@import "../../theme.scss";

.main {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 12px 8px 12px 8px;
}

.statusBar {
  display: flex;
  font-family: "GeistMono", monospace;
  font-weight: 501;
  font-size: 14px;
  line-height: 20px;
  gap: 8px;
  text-align: center;
  padding: 12px 8px 12px 8px;
}

.solved {
  font-size: 12px;
  gap: 8px;
  background: var(--blue_200);
  color: var(--blue_900);
  border-radius: 32px;
  align-items: center;
  justify-content: center;
  display: flex;
  padding: 0px 10px;
}
.closed {
  font-size: 12px;
  gap: 8px;
  background: var(--blue_200);
  color: var(--blue_900);
  border-radius: 32px;
  align-items: center;
  justify-content: center;
  display: flex;
  padding: 0px 10px;
}

.open {
  line-height: 12px;
  padding: 0px 10px;
  gap: 4px;
  background: var(--grey_200);
  border-radius: 32px;
  align-items: center;
  justify-content: center;
  display: flex;
  color: white;
}

.footer {
  font-family: "Geist";
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: --var(--ds_gray_600);
  padding: 12px, 8px, 12px, 8px;
  gap: 8px;
}
