@import "../../theme.scss";

div.topbarContainer {
  position: sticky;
  top: 0;
  display: flex;
  padding: 14px 24px;
  background-color: var(--backgroundDefault);

  border-bottom: 1px solid var(--accents_5);
  min-height: 60px;
  z-index: 1000;
  gap: 4px;

  h3 {
    display: flex;
    font-family: "GeistMono";
    align-items: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
    text-transform: capitalize;
    gap: 8px;
  }

  .button {
    cursor: pointer;
    &.sampling {
      cursor: default;
    }

    &.loading {
      @include skeleton;
      @include skeletonCardShape;
    }
  }
}

@keyframes shine {
  from {
    transform: translateX(-100%);
    visibility: visible;
  }
  to {
    visibility: visible;
    transform: translateX(100%);
  }
}
