@import "../../theme.scss";

.infoCard {
  background-color: var(--ds_gray_100) !important;
  border: 1px solid var(--ds_gray_200) !important;

  .infoCardContainer {
    display: flex;
    padding: 24px;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    width: 100% !important;
    padding: 8px;

    .topContainer {
      display: flex;
      align-items: center;
      gap: 8px;
      svg {
        width: 14px;
      }
      .cardTitle {
        font-family: "GeistMono";
        font-size: 12px;
        line-height: 150%;
        color: var(--ds_gray_600);
      }
    }

    .bottomContainer {
      display: flex;
      gap: 8px;
      align-items: center;

      h3.mainText {
        font-family: "GeistMono";
        color: var(--ds_gray_1000);
        font-size: 20px;
        font-weight: 700;
        line-height: 32px;
      }
    }
  }
}

svg.informationFill {
  color: var(--ds_gray_1000);
}

.skeletonCard {
  @include skeleton;
  &:nth-child(1)::after {
    animation-delay: 0.64s;
  }
  &:nth-child(2)::after {
    animation-delay: 0.65s;
  }
  &:nth-child(3)::after {
    animation-delay: 0.66s;
  }
  &:nth-child(4)::after {
    animation-delay: 0.67s;
  }
  &::after {
    width: 492%;
    background: linear-gradient(
      110deg,
      rgba(255, 255, 255, 0) 10%,
      rgba(255, 255, 255, 0.2) 50%,
      rgba(255, 255, 255, 0) 90%
    );
  }
}

@keyframes shine {
  from {
    transform: translateX(-100%);
    visibility: visible;
  }
  to {
    visibility: visible;
    transform: translateX(100%);
  }
}
