@import "../../theme.scss";

.record_container {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.record_main {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.footer {
  border-top: 1px solid var(--otherDivider);
  margin-top: 20px;
  padding-top: 20px;
  display: flex;
  justify-content: center;
  a {
    * {
      cursor: pointer;
    }
  }
}
