.container {
  display: flex;
  padding: 40px 160px;
  flex-direction: column;
  gap: 20px;

  div {
    display: flex;
    justify-content: flex-end;
  }
}
