@import '../../../../theme.scss';

.container{
  padding: 10px;
  border-radius: 4px;
  background: var(--backgroundPaper);
  border: 1px solid var(--otherDivider);
  margin: 65px 10px 10px 10px;
  display: flex;

  column-gap: 10px;
  width:100%;

  p {
    color: var(--textPrimary);
    font-size: 20px;
  }
}

.hide {
  visibility: hidden;
}
