@import "../../theme.scss";

.tabsContainer {
  display: flex;
  padding: 18px;
  padding-bottom: 0;
  background: var(--backgroundPaper);
  justify-content: space-between;
  span {
    display: flex;
    gap: 12px;
  }
}

.syncPlayCss {
  display: flex;
  gap: 8px;
  align-items: center;
  transform: translateY(-8px);
}

.tab {
  all: unset;
  cursor: pointer;
  padding: 12px 6px;
  padding-top: 16px;
  position: relative;
  border-bottom: 1px solid transparent;
  label {
    cursor: pointer;
  }
  transition: all 0.3s;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;

  h3 {
    color: var(--ds_gray_alpha_1000);
  }

  &:hover {
    background: var(--primaryMainFade);
  }

  .dotCss {
    position: absolute;
    top: 0;
    right: 0;
    width: 4px;
    height: 4px;
    border-radius: 100px;
    background: var(--primaryMainFade);
    transform: translateX(5px);
  }
}

.active {
  border-bottom: 1px solid var(--primaryMain);
}
