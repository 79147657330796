@import '../../../../../theme.scss';

.container{
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width:100%;

  main{
    display: flex;
    gap: 20px;
  }

  footer{
    display: flex;
    justify-content: flex-end;
  }

  h4{
    color:var(--textPrimary);
    margin:0;
  }
}


.integrationButton{
  background: transparent;  
  color:var(--textPrimary);
  display: flex;
  padding:30px 50px;;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap:12px;
  border:1px solid var(--otherDivider);

  img{
    width: 80px;
  }
  &:hover{
    background: transparent;
    border:1px solid var(--textPrimary);
  }
}


.active{
  border:1px solid var(--successGreen)
}

.channelListContainer{
  display: flex;
  flex-direction: column;
  gap:8px;
  overflow-y: scroll;
  flex-grow: 1;
}

.channelItem{
  color:var(--textPrimary);
  padding:6px 8px;
  border: 1px solid var(--otherDivider);
  border-radius: 4px;
  cursor: pointer;

  &:hover{
    border: 1px solid var(--textPrimary);
  }
}

.selected{
  border: 1px solid var(--primaryMain);
}

.header{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding:6px 0;
  button{
    padding:5px 15px;
    &:disabled{
      background: var(--actionDisabled);
      color:var(--textDisabled)
    }

  }
}