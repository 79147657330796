@import "../../theme.scss";

.container {
  padding: 20px;
  display: flex;
  flex-grow: 1;
  gap: 10px;

  & > div {
    width: 50%;
    display: flex;
    flex-direction: column;
  }

  @media (max-width: 768px) {
    padding: 10px;
    flex-direction: column;
    & > div {
      width: 100%;
    }
  }
}

.switch {
  display: none !important;
  .label {
    display: none !important;
  }
}

.rightContainer {
  border-radius: 20px;
  overflow: hidden;
  margin-top: 30px;
  border: 1px solid var(--otherDivider);
}

.scrollContainer {
  height: 300px;
  flex-grow: 1;
  overflow-y: scroll;
}

.smallContainer {
  visibility: visible;
}

.expanded-icon,
.collapsed-icon {
  svg {
    color: var(--textSecondary) !important;
  }
}

.array-key {
  color: gray !important;
}

.variable-value {
  div {
    color: var(--infoMain) !important;
  }
}

.copy-icon {
  svg {
    color: var(--infoLight) !important;
  }
}

.node-ellipsis {
  color: var(--warningMain) !important;
}

.rr-player {
  background: var(--backgroundDefault) !important;
}

.rr-controller {
  background: var(--backgroundDefault) !important;
  color: var(--textPrimary);
}

.rr-progress {
  border-top-color: var(--backgroundDefault) !important;
  border-bottom-color: var(--backgroundDefault) !important;
}

.rr-timeline {
  width: 100% !important;
}
.rr-controller__btns > * {
  color: var(--textPrimary) !important;
}

.rr-controller__btns > button > svg {
  color: var(--textPrimary) !important;
  fill: var(--textPrimary) !important;
}
.rr-timeline__time {
  color: var(--textPrimary) !important;
}

.switch {
  display: none !important;
  .label {
    display: none !important;
  }
}

.infoContainer {
  padding: 10px;
  flex-grow: 1;
  height: 200px;
  overflow-y: scroll;

  h2 {
    color: var(--textSecondary);
    font-size: 20px;
  }

  h3 {
    margin: 8px 0;
    color: var(--textSecondary);
  }

  span {
    font-size: 14px;
  }
}
