

.topContainer{

    display: flex;
    justify-content: flex-start;
    flex-grow: 1;
    flex-direction: column;

    .tabs{
        padding: 12px 16px !important;
        background: var(--ds_gray_100);
        border-radius: 12px;
        align-items: start !important;
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        gap:20px;
    
        // * {
        //     border-bottom: 0 !important;
        // }
        svg{
            margin-right: 0 !important;
        }

        :global(.scroll-container){
            padding-left: 0 !important;
            gap:10px;
        }

        :global(.tab){
            height: 36px;
            font-size: 13px;
            width:36px !important;
            padding: 0 !important;
            border-radius: 8px !important;
            text-align: center !important; 
            justify-content: center;
            margin: 0 !important;
        }
        :global(.active:not(.select)) {
            height: 36px;
            font-size: 13px !important;
            color:#FABE2A !important;
            background-color: #383115 !important;
            border-radius: 8px !important;
            padding: 2px 0 !important;
        }
        :global(.content){
            padding: 0;
            flex-grow: 1;
            width: 100%;
            display: flex;
            flex-direction: column;
        }
        :global(.highlight){
            width:36px !important;
            margin-left: 3.3px !important;
            height:36px !important;
            top:0px !important;
            border-radius: 8px !important; 
        }

    }
}



.topbar{
    position: absolute;
    top: 0;
    z-index: 1001;

    width: 459px;
    height: 60px;
    display: flex;
    align-items: center;
    padding: 0 24px;
    justify-content: flex-end;
    gap:12px;

    .feedbackContainer{
        display: flex;
        align-items: center;
        gap:4px;
        padding: 8px;
        border-radius: 8px;
        color:#A1A1A1;
        // cursor: pointer;
        // &:hover{
        //     background: #292929
        // }

        
        &.animationPositive{
            color: #0AC7B4 !important;
            svg{
                scale:1.4;
                transform: rotate(-40deg);
            }
        }

        &.animationNegative{
            color: #FF6166 !important; 
            svg{
                scale: 1.2;
                transform: rotate(-20deg);
            }
        }

        svg{
            width: 16px;
            height: 16px;
            transition: all .3s ease-in-out;
        }

        font-size: 14px;
    }


    :global(.btn){
        padding: 8px 0 !important;
        height: unset !important;
        line-height: unset !important;
    }
}

.emptyStateContainer{
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: center;
    padding-top:200px;
    width: 100%;
    color:#D0D0D0;
    flex-grow: 1;
    height: 200px;
    overflow-y: scroll;

    h6{
        font-weight: 800;
    }

    small{
        font-size: 13px;
    }
}


.conversationContainer{
    padding:8px;
    max-height: 400px;
    min-height: 200px;
    overflow-y: scroll;
}
.conversation{
    padding:16px 8px;
    display: flex;
    flex-direction: column;
    gap:24px;
    border-bottom:1px solid #FFFFFF17;

    span{
        color:#D0D0D0;
        font-size:13px;
    }

    label{
        color:#8F8F8F;
        font-size: 13px;
    }
}

.emptyConversations{
    text-align: center;
    margin-top: 50px;
    color:#8F8F8F;
    font-size: 13px;
}


.customCollapseContianer{

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 4px;
    gap:4px;
    padding:16px 16px 16px 0;
    b{
        color: #d0d0d0 ;
        font-size: 14px;;
    }

    span{
        color:#A1A1A1;
        font-size: 13px;;
    }
}

.choiceItem{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap:6px;

    span{
        color:#D0D0D0;
        font-size: 14px;
    }

    label {
        &.engaged{
            color:#29BC9B;
            font-size: 12px;
        }
        &.not_engaged{
            color:#e16c00;
            font-size: 12px;
        }
    }

}

.choiceList{
    display: flex;
    flex-direction: column;
    gap:20px;
    align-items:flex-start;
    padding:16px 32px;
}