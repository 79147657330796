@import "../../../../theme.scss";

.container {
  margin-left: 40px;
  gap: 10px;
  display: flex;
  margin-top: 12px;
  margin-bottom: 24px;

  button {
    padding: 6px 12px;
    border-radius: 64px;
    border: 1px solid var(--ds_gray_400);
    background: var(--ds_background_100);
    align-items: center;
    display: flex;
    gap: 10px;
    color: var(--ds_gray_1000);
    text-align: center;
    /* Text/14/Normal/500 */
    font-family: Geist;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    &:hover {
      border: 1px solid var(--ds_gray_600);
    }

    &.brain {
      padding: 4px 10px;

      svg {
        height: 20px;
        width: 20px;
      }
    }

    svg {
      height: 16px;
      width: 16px;
    }
  }
}

.drawer {
  padding: 0 !important;
  display: flex !important;
  width: 80vw !important;
  align-items: baseline;
  max-width: 1170px !important;

  .header {
    display: flex;
    gap: 24px;
    padding: 16px;
    align-items: center;
    width: 100%;
    border-bottom: 1px solid var(--ds_gray_200);

    button {
      padding: 0;
      display: flex;
      align-items: center;
      all: unset;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-items: center;
      border-radius: 6px;
      border: 1px solid var(--ds_gray_400);
      background: var(--ds_background_100);
      padding: 6px;

      &:hover {
        background: var(--ds_gray_100);
      }
    }

    h3 {
      margin: 0;
      color: var(--ds_gray_1000);
      /* Text/16/Normal/500 */
      font-family: Geist;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
      align-items: center;
      gap: 4px;
      display: flex;
    }
  }

  .body {
    flex-grow: 1;
    display: flex;
    gap: 24px;

    .left {
      width: 50%;
      padding: 24px 0;
      border-right: 1px solid var(--ds_gray_200);
    }

    .right {
      padding: 24px;
      width: 50%;
      min-width: 540px;
    }
  }
}

.loadAccuracyDrawer {
  @include skeleton;
  min-width: 1170px;
  height: 100%;
}

.callout {
  border-radius: 8px;
  display: flex;
  padding: 8px 16px;
  flex-direction: column;
  align-items: flex-start;
  background: var(--ds_gray_100);
  color: var(--ds_gray_600);
  font-family: "GeistMono";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  margin: 0 24px;
  margin-bottom: 8px;
  gap: 4px;
  text-align: start;

  p,
  h4 {
    color: var(--cool_gray_200);
    font-family: "GeistMono";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    margin: 0;
    text-wrap: wrap;
    word-wrap: break-word;
    width: 100%;
  }

  h4 {
    font-size: 14px;
    color: var(--ds_gray_900);
  }
}

.stats {
  display: flex;
  flex-direction: column;
  gap: 40px;
}
.statsContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  div {
    color: var(--ds_gray_600);
    /* Text/12/Monospace/400 */
    font-family: "GeistMono";
    text-align: left;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
  }
  label {
    color: var(--ds_gray_1000);
    /* Text/14/Normal/600 */
    font-family: Geist;
    text-align: left;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    span {
      color: var(--ds_gray_600);
      /* Text/12/Monospace/400 */
      font-family: "GeistMono";
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
    }
  }
}

.bodyLeftTopStats {
  display: flex;
  gap: 24px;
  margin-bottom: 24px;

  .circleStats {
    background: var(--ds_gray_100);
    margin-left: 24px;
    width: 194px;
    border-radius: 16px;
    width: 40%;
    padding: 10px 20px;
  }
}

.tabsContainer {
  > div {
    display: flex !important;
    flex-direction: column !important;
    gap: 8px !important;
    padding: 16px 24px;
  }
}

.tabsContainer {
  > div {
    display: flex !important;
    flex-direction: column !important;
    gap: 8px !important;
    padding: 16px 24px;
  }
}

.tryAgainContainer {
  display: flex;
  gap: 8px;
  margin-right: 40px;
  width: 90%;

  .tryAgain {
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;

    .inputTryAgain {
      display: flex;
      padding: 16px;
      align-items: center;

      gap: 10px;
      align-self: stretch;
      background: var(--ds_gray_200);
      border-radius: 16px 16px 0 0;

      textarea {
        all: unset;
        width: 100%;
        padding: 0;
        background: none;
        color: var(--ds_gray_500);

        font-family: Geist;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
      }

      button {
        border-radius: 32px;
        background: var(--ds_gray_1000);
        color: var(--ds_gray_200);
        width: 26px;
        height: 24px;
        padding: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        svg {
          width: 12px;
          height: 12px;
        }
      }
    }

    .bottomTryAgain {
      display: flex;
      padding: 8px 16px;
      flex-direction: column;
      align-items: flex-start;
      gap: 10px;

      border-top: 1px solid var(--ds_gray_100);
      background: var(--ds_gray_100);
      border-radius: 0 0 16px 16px;

      color: var(--ds_gray_600);
      text-align: center;
      font-family: Geist;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 12px;
    }
  }
}

.closeContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  button {
    height: 16px;
    padding: 0;
    width: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      height: 12px;
      width: 12px;
    }
  }
}

.configDisplay {
  display: flex;
  flex-direction: column;
  gap: 8px;
  font-size: 13px;

  span {
    font-size: 14px;
    font-weight: 600;
  }

  div {
    display: flex;
    align-items: center;
    gap: 4px;
  }
}

@keyframes shine {
  from {
    transform: translateX(-100%);
    visibility: visible;
  }
  to {
    visibility: visible;
    transform: translateX(100%);
  }
}
