@import "../../theme.scss";

.mainContainer {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 6vh 8vw;
  background: var(--geist_background);
  height: 100%;

  .selectContainer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 16px;
  }

  .tabs {
    font-family: "GeistMono";
    font-size: 14px;
    line-height: 20px;

    table {
      font-family: "Geist";
    }
  }

  .tabs {
    header {
      div {
        div {
          display: flex;
          align-items: center;
          gap: 5px;
        }
      }
    }
  }
}
