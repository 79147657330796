@import "../../theme.scss";

.mainContainer {
  display: flex;
  padding: 40px;
  flex-direction: column;
  background: var(--geist_background);
  font-family: "Geist";
  overflow-y: scroll;
    height: 200px;
    flex-grow: 1;
}

.gapBox {
  display: flex;
  gap: 1rem;
}

.articleContentContainer {
  flex: 0.7;
  background: var(--geist-sementic-background-background-secondary, #1a1a1a);
  width: 100%;
  padding: 60px 40px;
  border-radius: 12px;
  gap: 0.75rem;
  display: flex;
  max-width: 800px;
  flex-direction: column;
}

.rightSectionContainer {
  flex-direction: column;
  flex: 0.3;
}

.articleAnalysisContainer {
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  background: var(--geist-sementic-background-background-secondary, #1a1a1a);
  border-radius: 12px;

  ul {
    list-style-type: circle;
    padding: 0;

    li {
      font-size: 14px;
      cursor: default;
      &:not(:last-child) {
        margin-bottom: 40px;
      }
      &::before {
        content: none !important;
      }
    }
  }
}
// how much is 10px in rem

.metadataContainer {
  background: var(--geist-sementic-background-background-secondary, #1a1a1a);
  width: 100%;
  border-radius: 12px;
}
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.cta {
  display: flex;
  align-items: center;
  gap: 1.75rem;
}

.title {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.backArrow {
  cursor: pointer;
}

.originalLinkContainer {
  display: flex;
  align-items: center;
  gap: 4px;
  color: white;
}

.articleContent {
  line-height: 1.625rem;
  white-space: break-spaces;

}

.merticItemContainer {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}

.ticketConverageValue {
  font-size: 32px;
  display: flex;
  justify-content: center;
  font-weight: 600;
  gap: 0.625rem;
  flex-direction: column;
  align-items: center;
}

.label {
  font-size: 14px;
  color: var(--ds_gray_900);
}

.mertricsContainer {
  display: flex;
  padding: 1rem 0;
  margin: 1.5rem 0;
  gap: 7.5em;
}

.metadataContainer {
  display: flex;
  flex-direction: column;
  padding: 1.5rem;
  gap: 1rem;
}

.metadataItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 8px;
}

.labelContainer {
  display: flex;
  align-items: center;
  gap: 0.25rem;
}

.metricsItemValue {
  font-weight: 700;
  color: var(--ds_gray_800);
}

.topic {
  display: flex;
  align-items: center;
  gap: 4px;
  & > .dot {
    height: 8px;
    width: 8px;
    border-radius: 20%;
    display: inline-block;
    background-color: var(--ds_red_700);
  }
}

.trendingVolValueContainer {
  display: flex;
  align-items: center;
  gap: 0.125rem;

  &.success {
    color: var(--ds_green_900);
  }

  &.error {
    color: var(--ds_red_900);
  }
}

.trafficLightDiv {
  width: 49px;
  height: 4px;
  display: flex;
  & > * {
    width: 33%;
    height: 100%;
  }
  & > :first-child {
    background: var(--ds_red_900);
  }
  & > :nth-child(2) {
    background: var(--ds_yellow_700);
  }
  & > :last-child {
    background: var(--ds_green_900);
  }
}

.labelWithInfoContainer {
  display: flex;
  align-items: center;
  gap: 0.25rem;
  position: relative;
}

.tooltip {
  display: flex !important;
}
