.container{

    flex-grow: 1;
    display: flex;
}

.chatContainer{
    background: var(--backgroundDefault);
    width:100%;
    display: flex;
}

.configurationPanelContainer{
    background:var(--ds_gray_100);
    border-left: 1px solid var(--accents_5);
    max-width: 460px;
    min-width: 460px;
    display: flex;
    flex-direction: column;
}