@import "../../theme.scss";

.mainContainer {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 3vh 12%;
  background: var(--geist_background);
  font-family: "Geist";
  height: 92vh;
  max-height: 92vh;
}

.sorting {
  padding: 16px;
  background-color: green;
  width: fit-content;
}

.panelContainer {
  display: flex;
  position: relative;
  height: 100%;
  border-radius: 12px;
  border: 1px solid var(--ds_gray_alpha_100);
  background: var(--ds_background_1000);

  .leftContainer {
    border-radius: 12px 0 0 12px;
    transition: all 0.5s ease-in-out;
    border-right: 1px solid var(--ds_gray_alpha_100);
    display: flex;
    flex-direction: column;
  }

  .rightContainer {
    flex-grow: 1;
    border-radius: 0 12px 12px 0;
    display: flex;
    flex-direction: column;
  }

  .skeleton {
    width: 100%;
    height: 100%;
    @include skeleton;
  }
}

.header {
  min-width: 93px;
  height: 66px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid var(--ds_gray_alpha_100, rgba(255, 255, 255, 0.06));
  position: relative;

  .text {
    transition: all 0.3s ease-in-out;
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 8px 16px;

    .title {
      display: flex;
      gap: 8px;
      align-items: baseline;

      .label {
        color: var(--ds_gray_1000);
        font-family: Geist;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
      }

      span {
        color: var(--ds_gray_600);
        font-family: Geist;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
      }
    }

    .stats {
      display: flex;
      gap: 8px;
      color: var(--ds_gray_600);
      font-family: "GeistMono";
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
      align-items: center;

      label {
        color: var(--giest_cyan);
      }
    }
  }
}
.expandBtn {
  all: unset;
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-left: 1px solid var(--ds_gray_alpha_100, rgba(255, 255, 255, 0.06));
  cursor: pointer;
  position: absolute;
  height: 100%;
  right: 0;
  box-sizing: border-box;

  &:hover {
    background: unset;
  }

  svg {
    height: 20px;
    width: 20px;
  }
}

.commonQuestionsContainer {
  flex-grow: 1;
  overflow: auto;
}

.chatHeader {
  padding: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid var(--ds_gray_200);
  background: var(--ds_background_1000);
}

.navigationBtn {
  all: unset;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-items: center;
  border-radius: 6px;
  border: 1px solid var(--ds_gray_400);
  background: var(--ds_background_100);
  padding: 6px;
  svg {
    width: 20px;
    height: 20px;
  }

  &:hover {
    background: var(--ds_gray_100);
  }
}

.chatHeaderCenter {
  display: flex;
  flex-direction: column;
  gap: 8px;
  .top {
    display: flex;
    gap: 8px;
    align-items: center;
    color: var(--ds_gray_600);
    font-family: "GeistMono";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    align-self: center;

    label {
      color: var(--giest_cyan);
    }
  }

  .headerQuestion {
    text-align: center;
    color: var(--ds_gray_1000);
    font-family: Geist;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    display: inline;
    position: relative;
  }

  .headerQuestion span:first-of-type {
    display: inline;
  }

  .headerQuestion span.icon {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin-left: 4px;
    vertical-align: middle;
  }

  .headerQuestion span.icon svg {
    width: 14px;
    height: 14px;
  }
}

.chatBody {
  display: flex;
  margin: 0 auto;
  max-width: 660px;

  transition: all 0.3s ease;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow: auto;

  &.expanded {
    margin: 0 auto;
  }

  .otherQuestion {
    margin-left: 50px;
    margin-top: 5px;
    color: var(--errorLight);
    font-family: Geist;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
  }
}

.chatFooter {
  border-top: 1px solid var(--ds_gray_alpha_100);
  display: flex;
  justify-content: space-between;

  .left {
    font-family: "GeistMono";
    font-size: 12px;
    line-height: 12px;
    color: var(--ds_red_900);
  }

  .right,
  .left {
    padding: 16px 24px;
    display: flex;
    align-items: center;
    gap: 8px;
    justify-content: flex-end;

    .savingStatus {
      color: var(--ds_gray_600);
      font-family: Geist;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 12px;
      padding: 0 12px;
      align-items: center;
      display: flex;
      gap: 4px;

      svg {
        color: var(--ds_gray_600);
        height: 16px;
        width: 16px;
      }
    }
  }

  .button {
    border-radius: 6px;
    padding: 6px 12px;
    text-align: center;
    font-family: Geist;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    text-transform: capitalize;
    cursor: pointer;
    &.primaryBtn {
      background: var(--ds_gray_1000);
      color: var(--ds_background_100);

      &:hover {
        background: var(--ds_gray_2000);
      }
    }

    &.secondaryBtn {
      border: 1px solid var(--ds_gray_400);
      background: none;
      color: var(--ds_gray_1000);

      &:hover {
        border: 1px solid var(--ds_gray_600);
      }
    }

    &.loading {
      @include skeleton;
      @include skeletonCardShape;
    }
  }
}

.feedbackRating {
  display: flex;
  gap: 8px;
  border-radius: 8px;
  font-size: 14px;

  .feedbackRatingItem {
    display: flex;
    gap: 4px;
    align-items: center;

    &.down {
      color: var(--ds_red_900);
    }

    &.up {
      color: var(--gesit_cyan_dark);
    }
  }
}

@keyframes shine {
  from {
    transform: translateX(-100%);
    visibility: visible;
  }
  to {
    visibility: visible;
    transform: translateX(100%);
  }
}
