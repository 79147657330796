@import '../../../../theme.scss';

.add_member_container {
  padding: 6px;
  display: flex;
  flex-direction: row;
  background: var(--backgroundPaper);
  justify-content: space-between;
  gap: 10px;
  border-radius: 8px;
  align-items: center;
}

input {
  padding: 10px ;
  font-size: 16px;
  background: var(--backgroundPaper);
  color: var(--textPrimary);
  border:none;
  outline:none;
  width:100%;

  &::placeholder {
    color: var(--textSecondary);


  }
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: var(--textPrimary);
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: var(--textPrimary);
}

::-ms-input-placeholder { /* Microsoft Edge */
  color: var(--textPrimary);
}

button{
  cursor: pointer;
}
// button {
//   padding: 10px 30px;
//   font-size: 16px;
//   border-radius: 4px;
//   background: var(--primaryMain);
//   border:none;
//   cursor: pointer;
//   white-space: nowrap;

//   &:hover{
//     background:var(--warningMain);
//   }

//   &:disabled{
//     background:var(--actionDisabled);
//     color:var(--textSecondary);
//     cursor: not-allowed;
    
//   }
// }
