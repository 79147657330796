@import "../../theme.scss";

.drawer {
  padding: 0 !important;
  display: flex !important;
  width: 80vw !important;
  align-items: baseline;
  width: fit-content !important;
  background-color: var(--geist_primary_background) !important;


  .header {
    display: flex;
    gap: 24px;
    padding: 16px;
    align-items: center;
    width: 100%;
    border-bottom: 1px solid var(--ds_gray_200);

    h3 {
      margin: 0;
      color: var(--ds_gray_1000);
      font-family: Geist;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      align-items: center;
      display: flex;
      gap: 4px;

      svg {
        width: 20px;
        height: 20px;
      }
    }

    button {
      padding: 0;
      display: flex;
      align-items: center;
      all: unset;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-items: center;
      border-radius: 6px;
      border: 1px solid var(--ds_gray_400);
      background: var(--ds_background_100);
      padding: 6px;

      &:hover {
        background: var(--ds_gray_100);
      }
    }
  }
}
