@import "../../../theme.scss";

.circleDiv {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 12px 0;
  width: 100%;

  .circleCont {
    width: 100%;
    margin: 0;
    position: relative;

    .circle {
      stroke-width: 2;
      fill: none;
      stroke-linecap: round;
      animation: progress 1s ease-out forwards;
    }

    .backgroundCircle {
      stroke: var(--ds_gray_400);
      stroke-width: 2;
      fill: none;
    }
  }

  .detailsContainer {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: "GeistMono";
    gap: 4px;

    .number {
      color: var(--ds_gray_1000);
      font-size: 20px;
      font-weight: 700;
      line-height: 32px;
    }

    .label {
      color: var(--ds_gray_600);
      font-size: 12px;
      font-weight: 400;
      line-height: 150%;
    }
  }

  .color1 {
    stroke: var(--geist_success);
  }
  .color2 {
    stroke: var(--ds_yellow_700);
  }
  .color3 {
    stroke: var(--ds_red_900);
  }
  .color4 {
    stroke: var(--ds_pink_600);
  }
  .color5 {
    stroke: var(--geist_violet_light);
  }
  .color6 {
    stroke: var(--geist_color_cyan);
  }
  .color7 {
    stroke: var(--ds_green_900);
  }
  .color8 {
    stroke: var(--pastel_brown);
  }
}

@keyframes progress {
  0% {
    stroke-dasharray: 0 100;
  }
}
