@import "../../theme.scss";

.fontColor-primary {
  color: var(--textPrimary);
  width: fit-content;
}

.fontColor-secondary {
  color: var(--textSecondary);
  width: fit-content;
}

.fontColor-disabled {
  color: var(--textDisabled);
  width: fit-content;
}

.pageHeadline {
  font-family: "Sora";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 100%;
  display: flex;
  align-items: center;
}

.headLine {
  font-family: "Sora";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 100%;
  display: flex;
  align-items: center;
}

.tableBody {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 130.19%;
  display: flex;
  align-items: center;
}

.tableLink {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 130.19%;
  display: flex;
  align-items: center;
  text-decoration-line: underline;
}

.tableButton {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 130.19%;
  display: flex;
  align-items: center;
}

.tableTag {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 130.19%;
  display: flex;
  align-items: center;
}

.button {
  font-family: "Sora";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 130.19%;
  display: flex;
  align-items: center;
}

.tab {
  font-family: "Sora";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 130.19%;
  display: flex;
  align-items: center;
}
