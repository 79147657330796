.container {
  width: 100%;
  max-width: 64rem; /* 4xl */
  margin: 0 auto;
  padding-top: 3rem; /* py-12 */
  padding-bottom: 3rem; /* py-12 */
  display: flex;
  flex-direction: column;
  gap:24px;
  

  @media (min-width: 768px) {
    padding-top: 5rem; /* md:py-20 */
    padding-bottom: 5rem; /* md:py-20 */
  }
}

.title {
  font-size: 1.5rem; /* 3xl */
  font-weight: 700; /* bold */
  letter-spacing: -0.05em; /* tighter tracking */

  @media (min-width: 640px) {
    font-size: 2.25rem; /* 4xl */
  }

  @media (min-width: 768px) {
    font-size: 3rem; /* 5xl */
  }
}


.grid{
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 1.5rem; /* 6 */

    @media (min-width: 640px) {
        grid-template-columns: repeat(2, 1fr);
    }

    @media (min-width: 1024px) {
        grid-template-columns: repeat(3, 1fr);
    }
}

.card{

    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 24px;
    padding:24px;
    flex-direction: column;

    img{
        width:80px;
        border-radius: 8px;
    }
}


