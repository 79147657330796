@import "../../../theme.scss";

.mainContainer {
  display: flex;
  flex-direction: column;
  gap: 116px;
  font-family: "Geist";
  flex-grow: 1;
  padding: 8px;
  border-radius: 12px;
  color: var(--ds_gray_900);

  h3,
  p,
  h4 {
    margin: 0;
    font-family: "Geist";
  }
  p {
    font-size: 14px;
  }
  h3 {
    font-weight: 700;
  }

  .mainHeader {
    gap: 10px;
    display: flex;
    flex-direction: column;
    size: 24px;
    line-height: 32px;
    h3 {
      font-weight: 700;
    }
  }

  .optionsTicketTopic {
    gap: 4px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 20px;
  }
  .optionsBar {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 4px;
  }

  .optionsBarLeft {
    display: flex;
    gap: 8px;
  }

  .cardsContainer {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    gap: 16px;
  }
}

.footer {
  gap: 8px;
  flex-grow: 1;
}

.createdAt {
  display: flex;
  gap: 24px;
  align-items: center;
  font-size: 14px;
  justify-content: flex-start;
  color: var(--ds_gray_600);
}
