@import "../../theme.scss";

.mainContainer {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 6vh 10vw;
  background: var(--geist_background);
  height: 100%;
  font-family: Geist;

  .headerContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 10px;

    .badgeContainer {
      display: flex;
      flex-grow: 1;
      justify-content: flex-end;
    }

    .titleContainer {
      display: flex;
      flex-grow: 1;
      justify-content: flex-start;
      align-items: center;
      gap: 16px;

      h1 {
        margin: 0;
        font-size: 24px;
        font-weight: 700;
        line-height: 32px;
        color: var(--ds_grey_1000);
      }
    }

    h5 {
      margin: 0;
      flex-grow: 1;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      color: var(--ds_gray_600);
    }

    svg {
      cursor: pointer;
    }
  }

  .cardsContainer {
    display: flex;
    flex-direction: row;
    gap: 16px;

    .leftSideContainer {
      width: 65% !important;
    }

    .sideContainer {
      display: flex;
      flex-direction: column;
      gap: 16px;
      width: 35%;
    }

    p {
      flex-grow: 1;
      margin: 0;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      color: var(--accents_3) !important;
    }

    div.articleContent {
      background-color: var(--ds_gray_100) !important;
      border: 1px solid var(--ds_gray_200) !important;

      .content {
        padding: 8px;
        display: flex;
        gap: 24px;
        flex-direction: column;

        svg {
          align-self: flex-end;
          height: 20px;
          width: 20px;
          cursor: pointer;

          transition: all 0.2s;
        }

        &.copyPressed {
          color: var(--gesit_cyan_dark);
        }
      }
    }

    div.articleDetails {
      background-color: var(--ds_gray_100) !important;
      border: 1px solid var(--ds_gray_200) !important;
      height: fit-content;
      // flex-grow: 1;
      position: sticky;
      top: 72px;

      .content {
        padding: 8px;
        display: flex;
        gap: 24px;
        flex-direction: column;
        flex-grow: 1;
        padding: 8px;
        display: flex;
        flex-direction: column;
        gap: 16px;

        .detailsRow {
          flex-grow: 1;
          display: flex;
          justify-content: space-between;

          h4 {
            margin: 0;
            flex-grow: 1;
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            color: var(--ds_gray_600);
          }

          h5 {
            margin: 0;
            flex-grow: 1;
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            color: var(--ds_gray_900);
            text-align: end;

            &.impact {
              color: var(--gesit_cyan_dark);
              font-family: "GeistMono";
            }
          }

          .topicsContainer {
            display: flex;
            gap: 2px;
          }
        }
      }
    }
  }

  &.loading {
    .headerContainer {
      h1,
      h5 {
        @include skeleton;
        @include skeletonCardShape;
      }

      h1 {
        &:first-child {
          &::after {
            animation-delay: 0.15s;
            width: 1500%;
          }
        }

        &:nth-child(2) {
          &::after {
            animation-delay: 0.18s;
            width: 500%;
          }
        }
      }

      h5 {
        &::after {
          animation-delay: 0.18s;
          width: 120%;
        }
      }
    }

    .cardsContainer {
      .articleContent {
        min-height: 500px;
        @include skeleton;
        color: transparent;
        &::after {
          animation-delay: 0.25s;
          width: 200%;
        }
      }

      .articleDetails {
        top: 0;
        @include skeleton;
        h4,
        h5 {
          color: transparent !important;
        }
        .content .detailsRow {
          h4,
          h5 {
            color: transparent !important;
          }
        }

        &::after {
          animation-delay: 0.5s;
          width: 400%;
        }
      }
    }
  }
}

@keyframes shine {
  from {
    transform: translateX(-100%);
    visibility: visible;
  }
  to {
    visibility: visible;
    transform: translateX(100%);
  }
}
