.container{
    display: flex;
    flex-direction: column;
    padding:40px;
    gap:40px;
}

.header{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.simulator{
    display: flex;
    padding:20px;
    cursor: pointer;
    border-radius: 8px;
    align-items: center;
    gap: 50px;
    div{
        width:400px;
        gap:8px;
        display: flex;
        flex-direction: column;
    }

    *{
        cursor: pointer;
    }
    &:hover{
        background:var(--backgroundPaper)
    }


    label{
        color:#8F8F8F;
        gap:4px;
        display: flex;
        align-items: center;
        svg{
            height:16px;
            width:16px;
            color:#8F8F8F;

        }
    }
}

.editableTitle{
    display: flex;
    align-items: center;
    gap:8px;
    h6{
        margin:0;
    }

    button{
        all:unset;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    svg{
        height: 16px;
        width: 16px;
    }
}