@import "../../../../theme.scss";

.container {
  display: flex;
  gap: 20px;
  width: 100%;
  flex-direction: column;
  .bars {
    display: flex;
    align-items: center;
    flex: 1;
    justify-content: flex-start;
    background-color: var(--ds_gray_400);
    gap: 6px;
  }
}
.smallerContainer {
  gap: 20px;
  width: 100%;
}
div.dashboardTable {
  background-color: var(--ds_gray_100) !important;
  border: 1px solid var(--ds_gray_alpha_100) !important;

  input {
    border-radius: 20px;
    width: 60px;
  }

  .cardContent {
    border-radius: 12px;
    color: var(--accents_3);
  }

  &.loading {
    min-height: 350px;
    @include skeleton;
  }
}

.leftContainer {
  gap: 8px;
  display: flex;
  flex-direction: column;
  width: 80%;
}

.row {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  width: 100%;
  height: 100%;
}

.category {
  width: 200px; /* Adjust width as needed */
  font-weight: bold;
  border-radius: 15px; /* Adjust radius as needed */
  align-items: center !important
}


.bar {
  height: 16px;
  border-radius: 4px;
}

.rightContainer {
  display: flex;
  flex-direction: column;
  padding: 0px 24px;
  justify-content: flex-start;
  align-items: flex-start;
  width: 197.5px;
  max-height: 160px;
  font-family: Arial, sans-serif;
  font-size: 14px;
}

.labelItem {
  display: flex;
  align-items: center;
  margin: 5px;
}

.colorBox {
  width: 10px;
  height: 10px;
  margin-right: 5px;
  border-radius: 2px;
}

.general_request {
  background-color: #c0620a;
}

.feature_request {
  background-color: #80e0b6;
}

.feedback {
  background-color: #fff780;
}

.troubleshooting {
  background-color: #ffbd33;
}

.bug {
  background-color: #ff6666;
}

.other {
  background-color: #bcbcbc;
}
.information_or_education {
  background-color: #8ec3ff;
}



.showMore button {
  display: flex;
  height: 32px;
  padding: 0px 12px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 9999px;
  border: 1px solid var(--accents_5);
  background-color: inherit;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  color: var(--ds_gray_1000);
  transition: all 0.2s;
}

.showMore button:hover {
  background-color: var(--ds_gray_100);
}

.showMore.showMore svg {
  transform: rotate(180deg);
  transition: all 0.3s ease-in-out 0.1s;
}

.loading {
  @include skeleton;
}
@keyframes shine {
  from {
    transform: translateX(-100%);
    visibility: visible;
  }
  to {
    visibility: visible;
    transform: translateX(100%);
  }
}
