@import "../../theme.scss";

div.dashboardTopicViewCard {
  background-color: var(--ds_background_100) !important;
}

.cardContent {
  padding: 8px;
  border-radius: 12px;
  color: var(--ds_gray_900);
}

.gtable {
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;

  thead {
    background-color: inherit;

    tr {
      background-color: inherit;
      border: none;

      th {
        background-color: inherit;
        text-align: start;
        border-bottom: solid 1px var(--ds_gray_200);
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        text-transform: none;
        color: var(--accents_5);
        padding: 12px 8px;

        &.topic {
          width: 17rem;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        &.tags {
          width: 30%;
        }
      }
    }
  }

  tbody {
    tr.tr {
      td {
        color: var(--ds_gray_900);
        border: none;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        padding: 12px 8px;
        transition: all 0.2s;

        &:first-child {
          display: flex;
          div {
            border-top-left-radius: 6px;
            border-bottom-left-radius: 6px;
            width: 17rem;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;

            div {
              width: 100%;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
          gap: 6px;
        }
        &:last-child {
          border-top-right-radius: 6px;
          border-bottom-right-radius: 6px;
        }

        // cursor: pointer;
        &.data {
          font-weight: 400;
          font-family: "GeistMono";
          &.tags{
            min-width: 30%;
            display: flex ;
            gap: 4px;
            flex-direction: row;
            flex-wrap: wrap;

          }
        }
      }

      &:hover,
      &.selectedRow {
        background-color: var(--ds_gray_100);
  
      }
    }
    tr.divider {
      td {
        padding: 4px;
      }
    }

    tr.showMore {
      td div {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        button {
          display: flex;
          height: 32px;
          padding: 0px 12px;
          justify-content: center;
          align-items: center;
          gap: 4px;
          border-radius: 9999px;
          border: 1px solid var(--accents_5);
          background-color: inherit;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
          color: var(--ds_gray_1000);
          transition: all 0.2s;

          &:hover {
            background-color: var(--ds_gray_100);
          }

          &.showMore {
            svg {
              transform: rotate(180deg);
              transition: all 0.3s ease-in-out 0.1s;
            }
          }
        }

        span {
          max-height: 1px;
          min-height: 1px;
          background-color: var(--accents_5);
          opacity: 0.6;
          width: 50%;
        }
      }
    }
  }
}

.linkToTicket {
  display: flex;
  align-items: center;
  gap: 6px;
  background: none;
  white-space: break-spaces;
  font-size: 12px;

  &:hover {
    background: var(--ds_gray_200);
  }

  svg {
    width: 16px;
  }
}
