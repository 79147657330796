.container {
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  > div {
    flex-grow: 1;
    height: 200px;
    overflow-y: scroll;
  }
}

div.opportunitiesLabel {
  display: flex;
  gap: 10px;
  position: relative;

  > span.opportunitiesCount {
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--ds_yellow_700);
    text-align: center;
    font-family: Geist;
    font-size: 13px;
    font-weight: 500;
    line-height: 11px;
    padding: 4px 6px;
    border-radius: 32px;
    background: var(--ds_yellow_900);
  }

  .beta {
    position: absolute;
    rotate: 15deg;
    top: -10px;
    right: -45px;
    z-index: 10000;
  }
}
