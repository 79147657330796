@import "../../theme.scss";

.toggleContainer {
  position: relative;
  cursor: pointer;
  width: min-content;
}

.disaled {
  cursor: default;
}

.container {
  display: flex;
  align-items: center;

  label {
    color: var(--otherDivider);
    //   color: ${disabled ? colors.primaryMain : colors.otherDivider};
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
  }
  .l {
    margin: 0;
    margin-left: 8px;
  }
  .r {
    margin: 0;
    margin-right: 8px;
  }
  .S {
    font-size: 14px;
    line-height: 130%;
  }
  .M {
    font-size: 16px;
    line-height: 20px;
  }
}

.disabledContainer {
  label {
    color: var(--primaryMain);
  }
}

.toggleBackground {
  display: block;
  width: 36px;
  height: 20px;
  background: var(--actionActive);
  border-radius: 100px;
}

.toggleBackground-disabled {
  background: var(--actionDisabled);
}

.toggleBackground-isPressed {
  background: var(--successGreen);
}

.toggleCircle {
  display: block;
  position: absolute;
  width: 16px;
  height: 16px;
  top: 2px;
  left: 2px;
  border-radius: 100px;
  cursor: pointer;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
  margin-left: 0;
  background: var(--backgroundDefault);
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    color: var(--errorLight);
  }
}

.toggleCircle-pressed {
  margin-left: 16px;
}

.toggleCircle-disabled {
  cursor: default;
}
