@import "../../theme.scss";

.container {
  display: flex;

  color: var(--textSecondary);
  gap: 4px;
  b {
    color: var(--textPrimary);
  }
  label {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
