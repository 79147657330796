@import "../../../../theme.scss";

div.dashboardCard {
  background-color: var(--ds_gray_100) !important;
  border: 1px solid var(--ds_gray_200) !important;
  position: relative;
  max-height: unset !important;
  height: fit-content !important;

  .content {
    padding: 16px;
    .top {
      .headline {
        display: flex;
        flex-grow: 1;
        flex-direction: column;
        gap: 8px;

        .smallHeadContainer {
          display: flex;
          gap: 8px;

          h5 {
            margin: 0;
            color: var(--ds_gray_600);
            font-family: "GeistMono";
            font-size: 12px;
            font-weight: 400;
            line-height: 18px;
          }

          svg {
            width: 14px;
            height: 14px;
          }
        }

        h2 {
          margin: 0;
          color: var(--ds_grey_1000);
          font-family: "GeistMono";
          font-size: 20px;
          font-weight: 700;
          line-height: 160%;

          &.bigHeadFont {
            font-family: Geist;
          }
        }
      }

      margin-bottom: 40px;

      &.removeMargin {
        margin-bottom: 0;
      }
    }
  }

  &.bottom {
    padding-bottom: 40px;
    position: relative;

    .bottom {
      position: absolute;
      bottom: 0;
      width: 92%;
      padding: 16px 10px;
      border-top: 1px solid var(--ds_gray_alpha_100);
      display: flex;
      justify-content: flex-end;

      .explore {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        cursor: pointer;
        width: fit-content;
      }
    }
  }

  .sunContainer {
    position: absolute;
    bottom: -7px;
    right: 20px;
    z-index: 1;
  }

  &.skeleton {
    .top .headline {
      .smallHeadContainer {
        h5 {
          color: transparent;
        }
        svg {
          color: transparent;
        }
      }
      h2 {
        color: transparent;
      }
    }

    @include skeleton;
    &::after {
      width: 492%;
      background: linear-gradient(
        110deg,
        rgba(255, 255, 255, 0) 5%,
        rgba(255, 255, 255, 0.2) 50%,
        rgba(255, 255, 255, 0) 95%
      );
    }

    &.autoStatus {
      &::after {
        animation-delay: 0.76s;
      }
    }

    &.knowledgeStatus {
      min-height: 404px;
      min-width: 40%;
      max-width: 40%;
      &::after {
        animation-delay: 0.69s;
      }
    }

    &.articlesGap {
      &::after {
        animation-delay: 0.71s;
      }
    }
  }

  @keyframes shine {
    from {
      transform: translateX(-100%);
      visibility: visible;
    }
    to {
      visibility: visible;
      transform: translateX(100%);
    }
  }
}
