@import '../../../../theme.scss';

.container{
  padding: 10px;
  border-radius: 4px;
  background: var(--backgroundPaper);
  border: 1px solid var(--otherDivider);
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  column-gap: 10px;
  color: var(--textPrimary);
  font-size: 18px;
  flex-grow: 1;
  height: 200px;
  overflow-y: scroll;
}
