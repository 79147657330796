@import "../../theme.scss";

.wrapper{
  display: flex;
  flex-direction: column;
  padding:4vh 8vw;
  gap:40px;
}

.topbar{
  display: flex;
  justify-content: flex-end;
}

.mainContainer {
  display: flex;
  flex-direction: row;
  gap: 32px;
  
  background: var(--geist_background);
  width: 100%;

  .leftSide {
    width: 40%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    gap: 16px;

    button.articleCTA {
      font-family: Geist;
      display: flex;
      padding: 6px 12px;
      justify-content: center;
      align-items: center;
      border-radius: 6px;
      background: var(--ds_gray_1000);
      color: var(--ds_background_100);
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      position: relative;
      z-index: 5;
    }

    .circularContainerWrapper {
      display: flex;
      flex-direction: column;
      gap: 24px;

      .circularContainer {
        padding: 0 32px;
      }

      .chartReader {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 16px;

        .topic {
          color: var(--ds_gray_600);
          font-family: "GeistMono";
          font-size: 12px;
          font-weight: 400;
          line-height: 150%;
          display: flex;
          gap: 8px;
          align-items: center;

          .dot {
            height: 8px;
            width: 8px;
            border-radius: 50%;
            display: inline-block;
          }

          &.color1 .dot {
            background-color: var(--geist_success);
          }
          &.color2 .dot {
            background-color: var(--ds_yellow_700);
          }
          &.color3 .dot {
            background-color: var(--ds_red_900);
          }
          &.color4 .dot {
            background-color: var(--ds_pink_600);
          }
          &.color5 .dot {
            background-color: var(--geist_violet_light);
          }
          &.color6 .dot {
            background-color: var(--geist_color_cyan);
          }
          &.color7 .dot {
            background-color: var(--ds_green_900);
          }
          &.color8 .dot {
            background-color: var(--pastel_brown);
          }
        }
      }
    }
  }

  .rightSide {
    width: 60%;
    display: flex;
    flex-direction: column;
    gap: 16px;
    height: fit-content;

    .mockTopic {
      width: 100%;
      height: 60px;
      @include skeleton;
      @include skeletonCardShape;

      &:nth-child(1) {
        &::after {
          width: 200%;
          animation-delay: 0.07s;
        }
      }
      &:nth-child(2) {
        &::after {
          width: 200%;
          animation-delay: 0.08s;
        }
      }
      &:nth-child(3) {
        &::after {
          width: 200%;
          animation-delay: 0.09s;
        }
      }
      &:nth-child(4) {
        &::after {
          width: 200%;
          animation-delay: 0.1s;
        }
      }
      &:nth-child(5) {
        &::after {
          width: 200%;
          animation-delay: 0.11s;
        }
      }
      &:nth-child(6) {
        &::after {
          width: 200%;
          animation-delay: 0.12s;
        }
      }
    }
  }
}

@keyframes shine {
  from {
    transform: translateX(-100%);
    visibility: visible;
  }
  to {
    visibility: visible;
    transform: translateX(100%);
  }
}

.results{
  display: flex;
  flex-direction: column;
  gap:12px;
  margin-top: 24px;
}

.result{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding:8px;
  border-radius: 8px;
  cursor: pointer;
  gap:12px;
  
  label{
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: pointer;

  }
  
  svg{
    height: 16px;
    width: 16px;
  }

  &:hover{
    background:#1A1A1A;
  }
}