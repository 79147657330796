

.sampleContainer{
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

.header{
    padding:40px;
    display: flex;
    flex-direction: column;
    gap:16px;
    h2{
        color: var(--ds_gray_1000);
        font-family: Geist;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 32px;
        margin:0;
    }
    div{
        color: var(--ds_gray_600);
        font-family: "Geist Mono";
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
    }
}

.container{
    padding:40px 60px;
    display: flex;
    flex-direction: column;
    gap:40px;
}

.overviewHeader{
    display: flex;
    gap:12px;
}

