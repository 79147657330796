@import "../../../../theme.scss";

.container {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  min-width: 550px;
  max-width: 650px;

  h5 {
    margin: 0;
    color: var(--ds_gray_600);
    font-family: "GeistMono";
    font-size: 12px;
    font-weight: 400;
    line-height: 150%;
    text-align: start;

    &.trend {
      font-family: "GeistMono";
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;

      &.red {
        color: var(--ds_red_900);
      }
      &.green {
        color: var(--gesit_cyan_dark);
      }
    }
  }

  .upper {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 16px 24px;

    .header {
      padding: 8px 0;
      display: flex;
      flex-direction: column;
      gap: 8px;
      h3 {
        margin: 0;
        color: var(--ds_gray_1000);
        font-family: "GeistMono";
        font-size: 20px;
        font-weight: 700;
        line-height: 32px;
        text-align: start;
      }
    }

    .details {
      display: flex;
      flex-direction: column;
      padding: 16px 0;
      gap: 24px;
      .detail {
        display: flex;
        flex-direction: column;
        gap: 4px;

        .dataRow {
          display: flex;
          align-items: center;
          gap: 4px;

          h4 {
            margin: 0;
            color: var(--ds_gray_1000);
            font-family: "GeistMono";
            font-size: 16px;
            font-weight: 700;
            line-height: 24px;

            &.red {
              color: var(--ds_red_900);
            }
            &.green {
              color: var(--gesit_cyan_dark);
            }
          }
        }
      }
    }
  }

  .downer .cardsContainer {
    padding: 16px 24px;
    display: flex;
    flex-direction: column;
    gap: 8px;

    .card {
      display: flex;
      justify-content: space-between;
      flex-grow: 1;
      padding: 16px;
      border-radius: 8px;
      background-color: var(--ds_gray_alpha_300);

      .left {
        display: flex;
        flex-direction: column;
        gap: 6px;
        flex-grow: 1;

        .cardHead {
          display: flex;
          gap: 8px;
          align-items: center;

          span {
            color: var(--ds_gray_1000);
            font-family: Geist;
            font-size: 13px;
            font-weight: 400;
            line-height: 16px;
          }

          svg {
            width: 12px;
            height: auto;
            cursor: pointer;
          }
        }

        h5 {
          font-family: Geist;
          font-size: 12px;
          font-weight: 300;
          line-height: 12px;
        }
      }
    }
  }
  .feedbackRating {
    display: flex;
    gap: 12px;
    border-radius: 8px;

    .feedbackRatingItem {
      display: flex;
      gap: 4px;
      align-items: center;
      color: var(--gesit_cyan_dark);
      &.down {
        color: var(--ds_red_900);
      }
    }
  }

  &.loading {
    @include skeleton;
  }
}
.callout {
  border-radius: 8px;
  display: flex;
  padding: 8px 16px;
  flex-direction: column;
  align-items: flex-start;
  background: var(--ds_gray_100);
  color: var(--ds_gray_600);
  font-family: "GeistMono";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  gap: 4px;
  text-align: start;

  p,
  h4 {
    color: var(--cool_gray_200);
    font-family: "GeistMono";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    margin: 0;
  }

  h4 {
    font-size: 14px;
    color: var(--ds_gray_900);
  }
}

@keyframes shine {
  from {
    transform: translateX(-100%);
    visibility: visible;
  }
  to {
    visibility: visible;
    transform: translateX(100%);
  }
}
