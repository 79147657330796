@import "../../theme.scss";

.container {
  display: flex;
  padding: 40px 40px;
  flex-direction: column;
  gap: 20px;

  div {
    display: flex;
    justify-content: space-between;
  }

  &.loading {
    min-height: 350px;
    @include skeleton;
  }
  .topbar {
    padding: 20px 20px;
    color: var(--ds_gray_700);
    font-family: Geist;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
    gap: 8px;
    align-items: center;
    display: flex;
    justify-content: flex-start;
  }
  &.data {
    font-weight: 400;
    font-family: "GeistMono";
    height: 64px;
    align-items: center;
  }
}

.linkToTicket {
  display: flex;
  align-items: center;
  gap: 6px;
  background: none;

  &:hover {
    background: var(--ds_gray_200);
  }

  svg {
    width: 16px;
  }
}



.categoriesTabs {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  color: var(--ds_gray_1000);
  font-family: "GeistMono";
  font-size: 14px;
  font-style: normal;
  line-height: 20px;



  .content {
    padding-top: 0 !important;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow-y: auto;
    height: 200px;
  }
}

.loading {
  min-height: 350px;

  @include skeleton;
}
@keyframes shine {
  from {
    transform: translateX(-100%);
    visibility: visible;
  }
  to {
    visibility: visible;
    transform: translateX(100%);
  }
}
