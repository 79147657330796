.container{
    display: flex;
    flex-direction: column;
    gap: 8px;
    flex-grow: 1; 
    padding: 12px 24px;
    height: 200px;
    overflow-y: scroll;

    h4{
        display: flex;
        align-items: center;
        gap:8px;
        margin: 10px 0 !important;
        :global(.tooltip){
            display: flex;
            align-items: center;
            svg{
                height: 20px;
                width: 20px;
            }
        }
    }

    small{
        text-align: left;
        color: var(--ds_gray_600) !important;
        font-family: Geist;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px; /* 123.077% */
        display: flex;
    }
}

.policyOverviewContainer{

    flex-grow: 1;
    height: 200px;
    overflow-y: scroll;
}

.policyOverviewList{
    margin:12px 0;
    display: flex;
    flex-direction: column;
    gap:12px;
}


.feedbackContainer{
    display: flex;
    gap:8px;
    
    .positive{
        display: flex;
        gap:6px;
        align-items: center;
        svg{
            height:16px;
            width: 16px;
        }
    
            border-color: #0AC7B4;
            color:#0AC7B4;
    
    }
    .negative{
        display: flex;
        gap:6px;
        align-items: center;
        svg{
            height:16px;
            width: 16px;
        }
    
            border-color: #FF6166;
            color: #FF6166;;
    
    }
}

.instructionsCard{
    display: flex;
    
    position: relative;
    border-radius: 8px !important;
    background: var(--ds_gray_100) !important;
    justify-content: space-between;
    align-items: center !important;
    gap: 8px !important;
    align-self: stretch !important;
    :global(.content) {

        padding: 16px !important;
        display: flex;
        gap:12px;
        justify-content: space-between;
        flex-direction: column;
        >div{
            display: flex;
            justify-content: space-between;
        }
    }


    h6{
        color: var(--geist-sementic-text-text-primary, #D0D0D0);
        text-align: left;
        /* Text/16/Normal/600 */
        font-family: Geist;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px; /* 150% */


        align-items: center;
        margin: 0;
        display: flex;
    }

    button{
        min-width: 50px !important;
        width:50px !important;
        font-size: 10px !important;
        padding:0 !important;
        position: absolute !important;
        top:4px !important;
        right: 4px !important;
        :global(.text){
            align-items: center !important;
            display: flex !important;
            justify-content: center !important;
            svg{
                height: 16px;
                width: 16px;
            }

        }
    }


.feedbackContainer{
    display: flex;
    gap:8px;
    
    .positive{
        display: flex;
        gap:6px;
        align-items: center;
        svg{
            height:12px;
            width: 12px;
        }
    
            border-color: #0AC7B4;
            color:#0AC7B4;
    
    }
    .negative{
        display: flex;
        gap:6px;
        align-items: center;
        svg{
            height:12px;
            width: 12px;
        }
    
            border-color: #FF6166;
            color: #FF6166;;
    
    }
}

}

.excluded{
    opacity: 0.5;
    h6{
        text-decoration: line-through;
    }
}

.informationContainer{



    small{
        color: var(--ds_gray_600);
        text-align: center;

        font-family: Geist;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px; /* 123.077% */
        display: flex;
        align-items: center;
        gap: 6px;
    }

    
    .stats{
        gap:24px;
        display: flex;
    
    }
}

.countStat{
    display: flex;
    flex-direction: column;
    align-items: start;

    svg{
        height: 14px;
        width: 14px;
    }

    .negative{
        color: var(--ds_red_900) !important;
    }

    .positive{
        color:#0AC7B4 !important
    }
}




.cta{
    display: flex;
    align-items: center;
}


.stage{
    display: flex;
    align-items: center;
    gap:8px;
    svg{
        width: 16px;
        height: 16px;
        color:#0AC7B4 !important
    }
}

.skip{
    display: flex;
    align-items: center;
    gap:8px;
    svg{
        width: 16px;
        height: 16px;
        color:#FF6166 !important
    }
}

.callout{
    margin: 20px 0 ;
    display: flex;
    padding: 8px 40px 12px 16px;
    align-items: flex-start;
    gap: 16px;
    width: fit-content;

    border-radius: 4px;
    border-left: 1px solid rgba(10, 199, 180, 0.20);
    background: rgba(10, 199, 180, 0.05);

    color: var(--geist-sementic-text-text-primary, #D0D0D0);
    font-family: Geist;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 19.5px */
    margin-bottom: 110px;
}

.bottom{
    border-top: 1px solid var(--geist-sementic-border-border-card, rgba(255, 255, 255, 0.09));
    background: var(--geist-sementic-background-background-secondary, #1A1A1A);
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    padding: 24px 16px;
    justify-content: center;
    align-items: flex-end;
    gap: 24px;
    button{
        width: 60% !important;
    }
}


.dotPublished{
    font-size: 11px;
    color:#A1A1A1;
    :global(.icon){
        background-color: #0AC7B4 !important;
    }
} 
.dotNotReleased{
    font-size: 11px;
    color:#D0D0D0;
    :global(.icon){
        background-color: #8F8F8F !important;
    }
}