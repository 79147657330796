@import "../../../theme.scss";

.suggestionsContainer{
    display: flex;
    flex-direction: column;
    align-items: end;
    gap:8px;
}

.btnsContainer{
    width: 100%;
    display: flex;
    align-items: flex-start;
    gap:8px;
}


.cardContainer {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 12px;
    width: 100%;
    display: flex;
    gap: 16px;
    overflow-x: auto;

}



.card {
    background-color: var(--ds_gray_100) !important;
    cursor: pointer;
    transition: background-color 0.3s ease;
    max-width: 30%;

    >div{
        height: 100% !important;
        gap:50px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;   
        div{
            display: flex;
            flex-direction: column;
            gap:4px;
        }
    }

    h6 {
        display: -webkit-box;
        -webkit-line-clamp: 5;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    
    small{
        color:#878787 !important;
        font-family: GeistMono;
        font-size: 12px;
    }
    


}

.card:hover {
    background-color: var(--ds_gray_200) !important;
}

.btn{
    all:unset;
    background-color: var(--ds_gray_100);
    padding: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    border:1px solid #2E2E2E;
    cursor: pointer;
    transition: background-color 0.3s ease;

    svg{
        height: 16px;
        width: 16px;
    }

    &:hover{
        background-color: var(--ds_gray_200) ;

    }

}


.title {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    margin-bottom: 8px;
}



.skeletonCard {
    background-color: var(--ds_gray_100);
    height: 160px;
    width: 100%;
    border-radius: 8px;
    animation: pulse 1.5s infinite;
}

.verified{
    font-size: 12px;
    color:var(--ds_green_900);
    display: flex;
    align-items: center;
    gap:8px;
    svg{
        height: 16px;
        width: 16px;
    }
}

@keyframes pulse {
    0% {
        background-color: var(--ds_gray_100);
    }
    50% {
        background-color: var(--ds_gray_200);
    }
    100% {
        background-color: var(--ds_gray_100);
    }
}

