@import "../../theme.scss";

.container {
  display: flex;
  justify-content: space-between;
  padding: 24px;
  span {
    display: flex;
    gap: 8px;
  }
  .g-16 {
    gap: 16px;
  }

  @media (max-width: 768px) {
    padding: 12px;
  }
}
