@import "../../theme.scss";

.container {
  padding: 6px;
  border-radius: 10px;

  border: 1px solid var(--ds_gray_200);
  box-sizing: border-box;
  z-index: 100000000;

  background: var(--ds_background_100);
  cursor: pointer;

  max-height: 80vh;

  overflow: auto;

  &.disableOverflowScroll {
    overflow: visible;
  }
}

.button {
  display: flex;
  border-radius: 5px;
  cursor: pointer;
  width: 100%;
  border: none;
  padding: 8px;
  color: var(--textSecondary);
  cursor: pointer;
  background: var(--ds_background_100);
  color: var(--ds_gray_1000);
  font-family: Geist;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;

  align-items: center;
  justify-content: space-between;

  div {
    align-items: center;
    display: flex;
    gap: 8px;
  }

  svg {
    height: 14px;
    width: 14px;
  }
  &:hover {
    color: var(--textPrimary);
    background: var(--ds_gray_alpha_300);
  }
  &.selected {
    color: var(--textPrimary);
    background: var(--ds_gray_alpha_300);
  }
}

.active {
  background: var(--infoMain);
  color: white;
}
