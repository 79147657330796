@import "../../../../theme.scss";

.circleDetailsContainer {
  flex-grow: 1;
  display: flex;
  gap: 40px;
  padding: 0 30px;

  .circleWrapper {
    width: 40%;
    max-width: 365px;
    padding: 30px 5%;
  }

  .dataWrapper {
    display: flex;
    flex-direction: column;
    gap: 16px;
    justify-content: center;
    align-items: flex-start;
    padding-left: 40px;
    flex-grow: 1;

    .dataLine {
      display: flex;
      align-items: center;
      gap: 32px;

      color: var(--ds-gray-600);
      font-family: "GeistMono";
      font-size: 14px;
      font-weight: 400;
      line-height: 150%;

      .dot {
        height: 8px;
        width: 8px;
        border-radius: 50%;
        display: inline-block;
      }

      &.potential {
        .dot {
          background: var(--geist_violet_light);
        }
      }

      &.enabled {
        .dot {
          background: var(--geist_color_cyan);
        }
      }
      &.published {
        .dot {
          background: var(--geist_success);
        }
      }
    }
  }
}
