@import "../../../theme.scss";

.panelContainer {
    display: flex;
    position: relative;
    flex-grow: 1;
    border-radius: 0;
    border: 1px solid var(--ds_gray_alpha_100);
    background: var(--ds_background_1000);
  
    .leftContainer {
      border-radius: 12px 0 0 12px;
      transition: all 0.5s ease-in-out;
      border-right: 1px solid var(--ds_gray_alpha_100);
      display: flex;
      flex-direction: column;
    }
  
    .rightContainer {
      flex-grow: 1;
      border-radius: 0 12px 12px 0;
      transition: all .3s ease-in;
      display: flex;
      flex-direction: column;


      .header{
        align-items: center;
        padding: 0 24px;
        div{
            display: flex;
            gap:8px;
            align-items: center;
        }
    
        label{
            color: var(--ds_gray_900);
            font-family: Geist;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 12px;
        }
      }
    }
  
    .skeleton {
      width: 100%;
      height: 100%;
      @include skeleton;
    }
  }

  .header {
    min-width: 93px;
    height: 66px;
    display: flex;


    justify-content: space-between;
    border-bottom: 1px solid var(--ds_gray_alpha_100, rgba(255, 255, 255, 0.06));
    .text {
      transition: all 0.3s ease-in-out;
      display: flex;
      width: 100%;
      flex-direction: column;
      gap: 8px;
      padding: 8px 16px;
  
      .title {
        display: flex;
        gap: 8px;
        align-items: baseline;
  
        .label {
          color: var(--ds_gray_1000);
          font-family: Geist;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: 24px;
        }
  
        span {
          color: var(--ds_gray_600);
          font-family: Geist;
          font-size: 13px;
          font-style: normal;
          font-weight: 400;
          line-height: 16px;
        }
      }
  
      .stats {
        display: flex;
        gap: 8px;
        color: var(--ds_gray_600);
        font-family: "GeistMono";
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
        align-items: center;
  
        label {
          color: var(--giest_cyan);
        }
      }
    }
  }
  .expandBtn {
    all: unset;
    padding: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-left: 1px solid var(--ds_gray_alpha_100, rgba(255, 255, 255, 0.06));
    cursor: pointer;
  
    &:hover {
      background: unset;
    }
  
    svg {
      height: 20px;
      width: 20px;
    }
  }
  



  
  .headerRight{
    display: flex;
    gap: 40px !important;
  }

  .rightBody{
    display: flex;
    flex-grow: 1;
    .ranking{
        padding: 16px 32px;
        width: 65%;
        border-right: 1px solid var(--ds_gray_alpha_100);
        
        display: flex;
        flex-direction: column;
        gap:24px;
        .details{
            padding-top: 16px;
            display: flex;
            justify-content: space-between;
            flex-direction: row;
            div{
                display: flex;
                flex-direction: column;
                justify-content:space-between;
                label{
                    color: var(--ds_gray_600);
                    font-family: "Geist Mono";
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 150%;
                }
                b{
                    color: var(--ds_gray_1000);
                    font-family: Geist;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 20px;
                }

            }
            td{
              align-items:  flex-end;
              justify-content: flex-end;
            }
        }
        .saveAt{
          align-items:  flex-end;
              justify-content: flex-end;

        }


        .analyze{
            display: flex;
            flex-direction: column;
            gap:8px;
            .label{
                color: var(--ds_gray_600);
                /* Text/12/Monospace/400 */
                font-family: "Geist Mono";
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: 150%; 
            }
        }
    }
    .chat{
        padding: 24px 60px;
        width: 35%;
        display: flex;
        gap:24px;
        flex-direction: column;
        *{
          font-family: Geist;
        }
        .message{
            padding:8px 16px 16px 16px;
            border-radius: 16px;
            gap:10px;
            display: flex;
            word-break: break-word;
            flex-direction: column;
            .messageHeader{
                justify-content: space-between;
                display: flex;
                align-items: center;
                b{
                    color: var(--ds_gray_1000);
                    /* Text/14/Normal/700 */
                    font-family: Geist;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 20px; 
                }
                label{
                    color: var(--ds_gray_600);
                    text-align: right;
                    /* Text/12/Normal/400 */
                    font-family: Geist;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 12px;
                }
            }
            
        }

        .agentMessage{
            background: var(--ds_gray_200);
        }
    }
  }


.navigationBtn {
    all: unset;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-items: center;
    border-radius: 6px;
    border: 1px solid var(--ds_gray_400);
    background: var(--ds_background_100);
    padding: 6px;
    svg {
      width: 20px;
      height: 20px;
    }
  
    &:hover {
      background: var(--ds_gray_100);
    }
  }


  .shrinked{
    width: 65% !important;
  }

  .savedAt{
    color: var(--ds_gray_600);
    font-family: Geist;
    align-items: center;
    justify-content: center;
    font-size: 22px !important;
    font-style: normal;
    line-height: 12px;
  }


  .reasonsContainer{
    gap:8px;
    &>div {
      width: 100% !important;
    }
  }

  .commentContainer{
    display: flex;
    flex-direction: column;
    gap:8px
  }