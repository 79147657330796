@import "../../theme.scss";

.container {
  padding: 6px;
  background: var(--backgroundDefault);
  border: 1px solid var(--otherDivider);
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  gap: 20px;
  p {
    color: var(--textPrimary);
  }
}

.cards_container {
  padding: 6px;
  background: var(--backgroundDefault);
  display: flex;
  width: 100%;
  flex-direction: column;

  p {
    color: var(--textPrimary);
  }
}
.loading {
  color: black;
}

.members_container {
  flex-grow: 1;
  display: flex;
  height: 200px;
  flex-direction: column;
}
