@import "../../theme.scss";

.container{
  margin:0 auto;
    margin-top: 40px;

    p {
      color: var(--textPrimary);
      margin: 0;
      margin-top: 10px;
      font-size: 16px;
      font-weight: 200;
      text-align: center;
      display: flex;
      justify-content: center;
      gap: 10px;

      ol{
        text-align: left;
      }
      a{
        color: var(--textPrimary);

        font-weight: 200;
        margin: 0;
        &:hover{
          text-decoration: underline;
        }
      }
    }
}

.headLine{
    color: var(--textPrimary);
    font-size: 46px;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    font-weight: 400;
    margin: 0;
  
    svg {
      height: 36px;
      width: 36px;
      margin-left: 20px;
    }
}

.subHeadLine{
    color: var(--textPrimary);

    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;

    margin: 0;
  
    svg {
      height: 36px;
      width: 36px;
      margin-left: 20px;
    }
    font-size: 20px;
    margin-top: 10px;
    font-weight: 200;
}

.gifContainer{
    display: flex;
    justify-content: center;
    margin: 20px 0;
}

.gif{
    width: 400px;
    border-radius: 10px;
    box-shadow: 0 0 20px rgba(255, 255, 255, 0.1);
}