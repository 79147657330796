@import "../../../../theme.scss";

.container {
  padding: 10px;
  border-radius: 4px;
  background: var(--backgroundPaper);
  border: 1px solid var(--otherDivider);
  margin-top: 10px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 10px;
  color: var(--textPrimary);
  font-size: 18px;
  cursor: pointer;

  &:hover {
    border: 1px solid var(--textSecondary);
  }

  svg {
    color: var(--textPrimary);
    width: 30px;
    height: 30px;
  }
}

.status {
  width: 3px;
  height: 40px;
  border-radius: 16px;
}

.completed {
  background: var(--successGreen);
}
.inProgress {
  background: var(--warningMain);
}
.undefined {
  background: var(--actionDisabled);
}
.notAvalible {
  background: var(--errorMain);
}

.disabled {
  color: var(--otherDivider);
  &:hover {
    border: 1px solid var(--otherDivider);
    cursor: not-allowed;
  }
}

.selected {
  border: 1px solid var(--primaryMain);
}
