.tag {
  display: flex;
  padding: 0px 10px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 32px;
  text-align: center;

  font-family: Geist;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px;
  height: 24px;
  margin-right: 10px;
  &.enabled {
    background: linear-gradient(
      105deg,
      var(--gradient_1) 0%,
      var(--gradient_2) 100%
    );
    color: var(--geist_color_primary_geist_foreground);
  }

  &.disabled {
    background: var(--ds_gray_200);
    color: var(--ds_gray_1000);
  }

  &.published {
    color: var(--ds_background_100);
    background: var(--ds_gray_1000);
    margin-right: 0;
  }
  &.collapsed {
    background: none;
    color: none;
    padding: 0px;
  }
}
