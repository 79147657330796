
.message{
    padding:12px 24px;
    font-size: 14px;
    border-radius: 8px;
    font-family: Geist;
    line-height: 200%;
    background-color: #292929;
    white-space: break-spaces;
    word-break: break-word;
    position: relative;

    :global(.wrapper){
        width: 100% !important;
        border:none !important;
    }

    ol,ul{
        margin-top:0;
        margin-bottom: 0;
        display: flex;
        flex-direction: column;
        gap: 12px;
    }

    li{
        margin:0;
        line-height: 1.3em;
        &:before{
            content: "" !important;
            display: none !important;
        }
    }
}


.editMode{
    border:1px solid var(--blue_900);
}
.user{
    max-width:70%;
    width: fit-content;
    // width: 70%;

}


.assistantWrapper{
    display: flex;
    gap:8px;
    width: 100%;
    // width: fit-content;

    .sparklesIcon{
        background: var(--ds_gray_100);
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 36px;
        width: 36px;
        svg{
            width:16px;
            height:16px
            
        }
    }
}

.assistant{
    flex-grow: 1;
    align-self: end;
    background: var(--ds_gray_100);
    width: fit-content;
    div{
            a{
                color:white;
                font-weight: 600;
                text-decoration: underline;
            }
    }



}

.loading{
    width:50px;
    justify-content: flex-start;
}

.systemMessage{
    background: #1E1B13;
    border:1px solid #604C1A;

    .system{
        display: flex;
        align-items: center;
        gap:20px
    }
}


.btnContainer{
    position: absolute;
    bottom: 8px;
    right: 8px;
    display: flex;
    gap:8px;

    &.editablt{
        bottom:-54px;
    }
}


.btn{
    all:unset;
    background-color: #1F1F1F;
    padding: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    border:1px solid #2E2E2E;
    cursor: pointer;

    svg{
        height: 16px;
        width: 16px;
    }
    &:hover{
        border-color: #dddddd;
        color:#dddddd;
    }

    
}