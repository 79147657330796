@import "../../theme.scss";

.container {
  width: 80%;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  height: 100%;
}

.list {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 200px;
  overflow-y: scroll;
  color: var(--textPrimary);
  gap: 10px;
  .loadMore {
    align-self: flex-end;
    width: 20%;
    padding-bottom: 10px;
  }
}

.label {
  color: var(--textPrimary);
  padding: 8px 10px;
}

.item {
  display: flex;
  gap: 50px;
  text-align: center;
  // justify-content: space-between;
  align-items: flex-start;
  padding: 8px;
  border-radius: 8px;
  border: 1px solid var(--otherDivider);
  button {
    padding-top: 2px;
    padding-bottom: 2px;
    padding-left: 2px;
    padding-right: 2px;
  }
}

.step {
  border: 1px solid;
  position: relative;
  border-radius: 8px;
  padding: 8px 16px;
  display: flex;
  flex-direction: column;
  gap: 6px;

  label {
    // position: absolute;
    text-align: left;

    font-size: 12px;
  }
}

.stepConnector {
  border: 1px solid;
  position: absolute;
  width: 50px;
  left: 0;
  top: 25px;
  transform: translateX(-100%);
}

.success {
  border-color: var(--successGreen);
}

.pending {
  border-color: var(--primaryMain);
}

.inProgress {
  border-color: var(--textDisabled); //actionDisabled , actionActive
}

.insightsStep {
  display: flex;
  gap: 8px;
  min-width: 200px;
  max-width: 400px;
}

.insightMetadata {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.refreshBtn {
  align-items: center;
  justify-content: center;
  display: flex;
}

.insightContianer {
  display: flex;
  flex-direction: column;
  text-align: left;
  gap: 6px;
  max-width: 300px;
  min-width: 300px;
  flex-grow: 1;
}

.tag {
  padding: 2px 10px;
  border-radius: 4px;
  background: var(--infoLight);
  color: var(--otherDivider);
}

.openStepContainer {
  display: flex;
  position: relative;
  flex-direction: column;
  text-align: left;
  gap: 6px;
  max-width: 300px;
  min-width: 300px;

  h5 {
    margin: 0;
    font-size: 14px;
    text-decoration: underline;
  }

  p {
    margin: 0;
  }
}

.openStepDate {
  position: absolute;
  right: 0;
  top: 0;
  font-size: 12px;
  color: var(--textSecondary);
  transform: translateY(-130%);
}

.expandBtn {
  padding: 6px 12px !important;
  display: flex;
  justify-content: space-between;
  gap: 4px;
  align-items: center;
}
