@import "../../theme.scss";

.container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  background: var(--ds_gray_100);
}

.topbar {
  display: flex;
  padding: 40px 60px;

  color: var(--ds_gray_700);
  font-family: Geist;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
  gap: 8px;
  align-items: center;
}
