@import "../../../../theme.scss";

.container {
  display: flex;
  padding: 16px;
  gap: 16px;
  justify-content: space-between;
  align-items: center;
  transition: all 0.2s;
  .left {
    all: unset;
    display: flex;
    gap: 8px;
    max-width: 480px;
    align-items: center;
  }

  &:hover,
  &.active {
    background: var(--ds_gray_100);
    cursor: pointer;
  }
}

.label {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  display: flex;
  align-items: center;

  div {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.subLabel {
  color: var(--ds_gray_900);
}

.potential {
  font-family: "GeistMono";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;

  transition: all .3s ease-in;
}

.skeletonCommonQuestion {
  @include skeleton;
  height: 56px;
  padding: 16px;
  color: transparent;
}

.tooltipText {
  white-space: normal;
  word-break: break-all;
}

@keyframes shine {
  from {
    transform: translateX(-100%);
    visibility: visible;
  }
  to {
    visibility: visible;
    transform: translateX(100%);
  }
}


.ellipsis{
  width: 300px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}