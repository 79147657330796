.simulatorInputWrapper{
    display: flex;
    flex-grow: 1;

    textarea{
        height: 24px;
        flex-grow: 1;
        overflow: hidden ;            
        border:0;
        padding: 0;
        width: 100%;
        resize: none;
        background: transparent;
        &::placeholder {
            color: #A1A1A1 !important;
        }

        &:focus {
            &::placeholder {
            color: transparent !important;
          }
        }
    }
}

.instructions{
    background: #2A2720;
    padding:8px 16px;
    border-radius: 8px;
    gap:24px;
    small{
        color: #FABE2A !important;
        font-family: Geist !important;
        font-size: 16px !important;
    }
}
.feedback{
    background: #2A2223;
    padding:8px 16px;
    border-radius: 8px;
    gap:24px;
    small{
        color: #FF6166 !important;
        width:144px !important;
        font-family: Geist !important;
        font-size: 16px !important;
    }
}

.popover{
    box-shadow: none !important;
    button{
        background-color: var(--backgroundDefault) !important;
    }
}