@import "../../theme.scss";

.titleRow {
  color: var(--ds_gray_1000) !important;
  font-family: Geist;
  font-size: 12px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: 12px !important;

  border-bottom: 1px solid var(--ds_gray_200);
  background: var(--ds_gray_200);
  display: table-cell !important;
}

.footer {
  color: var(--ds_gray_1000) !important;
  font-family: Geist;
  font-size: 12px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: 12px !important;

  display: table-row !important;
  border-top: 1px solid var(--ds_gray_200);
  background: var(--ds_background_100);
}

div.dashboardTable {
  background-color: var(--ds_gray_100) !important;
  border: 1px solid var(--ds_gray_alpha_100) !important;

  input {
    border-radius: 20px;
    width: 180px;
  }

  .cardContent {
    border-radius: 12px;
    color: var(--accents_3);

    .gtable {
      width: 100%;
      border-collapse: collapse;
      table-layout: fixed;

      thead {
        background-color: inherit;

        tr {
          background-color: inherit;
          border: none;

          th {
            background-color: inherit;
            text-align: start;
            border-bottom: solid 1px var(--ds_gray_200);
            font-size: 14px;
            font-weight: 500;
            line-height: 20px;
            text-transform: none;
            color: var(--accents_3);
            padding: 12px 8px;

            &.topic {
              width: 17rem;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }

            &:first-child {
              width: 30%;
            }

            &.columnBorder {
              border-right: 1px solid var(--ds_gray_alpha_100);
              border-left: 1px solid var(--ds_gray_alpha_100);
              &:first-child {
                border-left: none;
              }
              &:last-child {
                border-right: none;
              }
            }

            &.headerBorder {
              border-bottom: 1px solid var(--ds_gray_alpha_100);
            }
          }
        }
      }

      tbody {
        tr.tr {
          td {
            color: var(--ds_gray_600);
            border: none;
            font-size: 14px;
            font-weight: 500;
            line-height: 20px;
            padding: 12px 8px;
            transition: all 0.2s;

            &:first-child {
              display: flex;
              border-top-left-radius: 6px;
              border-bottom-left-radius: 6px;
              width: 100%;

              div {
                border-top-left-radius: 6px;
                border-bottom-left-radius: 6px;
                width: 100%;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                overflow: hidden;
                color: var(--ds_gray_1000);
                text-overflow: ellipsis;
                font-family: Geist;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 20px;
                display: flex;
                align-items: center;
                min-height: 44px;

                div {
                  width: 100%;
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                }
              }
              gap: 6px;
            }

            &:last-child {
              border-top-right-radius: 6px;
              border-bottom-right-radius: 6px;
            }

            &.columnBorder {
              border-right: 1px solid var(--ds_gray_alpha_100);
              border-left: 1px solid var(--ds_gray_alpha_100);
              &:first-child {
                border-left: none;
              }
              &:last-child {
                border-right: none;
              }
            }

            cursor: pointer;
            &.data {
              font-weight: 400;
              font-family: "GeistMono";
              height: 64px;
              align-items: center;

              .starsRating {
                z-index: 100;
                width: 100%;
                display: flex;
                align-items: center;
                gap: 2px;

                .star {
                  cursor: pointer;
                  transition: all 0.2s ease-in-out;
                  &.unselectedStar {
                    color: var(--ds_gray_1000);
                  }
                  &.hoveredStar {
                    color: var(--primaryMain);
                    opacity: 0.75;
                  }
                  &.selectedStar {
                    color: var(--primaryMain);
                  }
                }
              }
            }

            &.rating {
              min-width: 180px;
            }

            &.green {
              color: var(--gesit_cyan_dark);
            }

            &.red {
              color: var(--geist_error_dark);
            }

            &.badge {
              padding: 10px 8px;
            }

            span.disabledBadge {
              background-color: var(--ds_gray_200);
              color: var(--ds_gray_1000);
            }

            &.track {
              div {
                width: 80%;
                display: flex;
                gap: 1px;
                span {
                  flex-grow: 1;
                }
              }

              .trackItem {
                width: 20px;
                height: 6px;
                background: var(--light_gray);
              }

              .red {
                background: var(--ds_red_700);
              }

              .yellow {
                background: var(--geist_brand_700);
              }

              .green {
                background: var(--ds_green_900);
              }
            }

            &.evaluation {
              display: flex;
              gap: 10px;
              align-items: center;
              height: 68px;

              span {
                display: flex;
                height: 8px;
                width: 8px;
              }

              &.red {
                color: var(--ds_red_700);
                span {
                  background-color: var(--ds_red_700);
                }
              }

              &.yellow {
                color: var(--geist_brand_700);
                span {
                  background-color: var(--geist_brand_700);
                }
              }

              &.green {
                color: var(--ds_green_900);
                span {
                  background-color: var(--ds_green_900);
                }
              }
            }

            &.text {
              textarea {
                height: 24px;
                flex-grow: 1;
                overflow: hidden;
                border: 0;
                padding: 0;
                width: 100%;
                resize: none;
                background: transparent;
                &::placeholder {
                  color: #a1a1a1 !important;
                }

                &:focus {
                  &::placeholder {
                    color: transparent !important;
                  }
                }
              }
            }
          }

          &:hover,
          &.selectedRow {
            td {
              background-color: var(--accents_2);
            }
          }
        }
        tr.divider {
          td {
            padding: 4px;
          }
        }

        tr.showMore {
          td div {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            button {
              display: flex;
              height: 32px;
              padding: 0px 12px;
              justify-content: center;
              align-items: center;
              gap: 4px;
              border-radius: 9999px;
              border: 1px solid var(--accents_5);
              background-color: inherit;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 20px;
              color: var(--ds_gray_1000);
              transition: all 0.2s;

              &:hover {
                background-color: var(--ds_gray_100);
              }

              svg {
                transition: all 0.3s ease-in-out 0.1s;
              }

              &.showMore {
                svg {
                  transform: rotate(180deg);
                }
              }
            }

            span {
              max-height: 1px;
              min-height: 1px;
              background-color: var(--accents_5);
              opacity: 0.6;
              width: 50%;
            }
          }

          &.loading {
            td div {
              button {
                border-color: transparent;
                color: transparent;

                svg {
                  color: transparent !important;
                }
              }
              span {
                background-color: transparent;
              }
              @include skeleton;
              @include skeletonCardShape;
              &::after {
                width: 300%;
              }
            }
          }
        }
      }
    }
  }

  &.loading {
    min-height: 550px;
    @include skeleton;
  }
}

@keyframes shine {
  from {
    transform: translateX(-100%);
    visibility: visible;
  }
  to {
    visibility: visible;
    transform: translateX(100%);
  }
}

.title {
  color: var(--ds_gray_1000);
  font-family: Geist;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
  margin: 0;
  margin-bottom: 16px;
}

.linkToTicket {
  display: flex;
  align-items: center;
  gap: 6px;
  background: none;
  border:none;
  &:hover {
    background: var(--ds_gray_200);
  }

  svg {
    width: 16px;
  }
}
