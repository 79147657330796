.container{
    display: flex;
    flex-grow: 1;
    max-width: 800px;
    margin:0 auto;
    flex-direction: column;
    gap:12px;
}

.topContainer{
    position: relative;
    height:68px;
    display: flex;
    justify-content: center;
    align-items: flex-end;


    .tabs{
        height: 44px !important;
        padding: 4px 4px !important;
        background: var(--ds_gray_100);
        border-radius: 12px;
        align-items: center !important;
        display: flex;
    
        * {
            border-bottom: 0 !important;
        }

        :global(.scroll-container){
            padding-left: 0 !important;
        }

        :global(.tab){
            height: 36px;
            font-size: 13px;
            width:92px !important;
            padding: 0 !important;
            border-radius: 8px !important;
            text-align: center !important; 
            justify-content: center;
            margin: 0 !important;
        }
        :global(.active){
            height: 36px;
            font-size: 13px !important;
            color:#FABE2A !important;
            background-color: #383115 !important;
            border-radius: 8px !important;
            padding: 2px 0 !important;
        }
        :global(.content){
            padding: 0;
        }
        :global(.highlight){
            width:92px !important;
            margin-left: 6.6px !important;
            height:36px !important;
            top:0px !important;
            border-radius: 8px !important; 
        }

    }
}

.badgesContainer{
    padding:0 40px;
    display: flex;
    position: relative;
    justify-content: center;
    

    span{
        width: fit-content !important;
        padding: 6px 10px !important;
        position: relative;
        z-index: 2;
        font-size:12px !important;
        border:4px solid var(--backgroundDefault) !important;
        background-color: #10233D !important; 
        color:#52A8FF !important;
        
    }
    .primary{
        background-color: #292929 !important; 
        color:#EDEDED !important;
    }

    &::after{
        content: " ";
        width: 90%;
        height: 1px;
        background: #292929;
        display: flex;
        position: absolute;
        left: 5%;
        top: 50%;
        z-index: 0;
        transform: translateY(-50%);
    }
}


.messagesContainer{
    flex-grow: 1;
    padding:24px 40px;
    gap:24px;
    display: flex;
    height: 200px;
    overflow-y: scroll;
    flex-direction: column;

}

.inputContainer{
    padding: 0 40px 8px 40px;
    display: flex;
    gap:8px;
    flex-direction: column;
}

.feedbackContainer{
    display: flex;
    min-height: 34px;
    gap:24px;
    align-items: center;
    justify-content: center;

    small{
        color:#878787 !important;
        font-size: 13px !important;
        font-family: Geist !important;
    }
    div{
        gap:8px;
        display: flex;
        .btn{
            all:unset;
            background-color: #1F1F1F;
            padding: 8px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 6px;
            border:1px solid #2E2E2E;
            cursor: pointer;
            svg{
                height: 16px;
                width: 16px;
            }

            &.positive{
                &:hover{
                    border-color: #0AC7B4;
                    color:#0AC7B4;
                }
            }
            &.negative{
                &:hover{
                    border-color: #FF6166;
                    color: #FF6166;;
                }
            }
        }
    }

}

.inputFieldContainer{
    padding: 16px;
    background-color: #1F1F1F;
    border-radius: 16px;
    display: flex;
    position: relative;
    align-items: center;
    gap:10px;

    
    .input{
        flex-grow: 1;
        display: flex;
        align-items: center;
        gap:24px;
        .iconContainer{
            display: flex;
            svg{
                width: 16px;
                height: 16px;
            }
        }

    }
    .submitContainer{
        button{
            all:unset;
            background-color: #EDEDED;
            padding: 6px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 6px;
            border:0;
            cursor: pointer;
            color:#0A0A0A;
            border-radius: 100px;
            svg{
                height: 12px;
                width: 12px;
            }

            &:hover{
                background-color: #CCCCCC;
            }
        }
    }
}

.bottomBar{
    padding:8px 16px;
    display: flex;
    justify-content: space-between;
    color:#878787;
    small{
        font-size: 13px;
        font-family: Geist;
    }

    kbd{
        background-color: #1F1F1F !important;
        border:none !important;
    }
}

.command{
    cursor: pointer;
    padding:0 4px;
    border-radius: 4px;
    &:hover{
        background-color: var(--ds_gray_200) !important;
    }
}


.resetButton{
    position: absolute;
    left: 24px;
    top: 30px;
    button{

        svg{
            height: 16px;
            width: 16px;
            margin-right: 4px;
        }
        min-width: unset !important;
        border: 1px solid #a7a7a7 !important;
        div{
            color:white !important
        }
        &:hover{

            border: 1px solid white !important;
        }

    }
}

.optionsContainer{
    position: absolute ;
    top:0;
    right: 51px;
    left: 55px;
    z-index:1000;
    transform: translateY(-95%);

    background:#1F1F1F;
    border:1px solid #FFFFFF14;
    border-radius:8px;
    padding:8px;
    display:flex;
    flex-direction: column;
    gap:16px;
}

.optionsHeader{
    display: flex;
    gap:8px;

    label{

        color:#A1A1A1;
        font-size: 12px;
    }
}

.optionsBody{
    display: flex;
    flex-direction: column;
    gap:12px;
}

.option{
    font-size: 13px;
    color:#D0D0D0;
    cursor: pointer;
    padding:8px;
    border-radius: 4px;;
    display: flex;
    justify-content: space-between;
    align-items: baseline;

    svg{
        height: 14px;
        width: 14px;
        display: none;
    }

    &:hover{
        background:#FFFFFF05;

        svg{
            display: block;
        }
    }
}

.close{
    position: absolute;
    top: 8px;
    right:8px;
    cursor: pointer;
}