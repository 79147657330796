@import "../../../theme.scss";

.aiAccuracy {
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  gap: 4px;

  .line {
    width: 100%;
    height: 2px;
    background-color: var(--light_gray);
    width: 100%;
    max-width: 20px;
    border-radius: 2px;

    &.colored {
      background-color: var(--ds_amber_900);
    }
  }
}
