@import "../../../theme.scss";

.mainContainer {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 3vh 5vw;
  background: var(--geist_background);
  font-family: "Geist";
  height: 100%;

  .mainHeader {
    gap: 4px;
    display: flex;
    flex-direction: column;

    .selectTimeFilter {
      width: 18%;
      align-self: flex-end;
      font-size: 16px;

      &.loadSelect {
        color: transparent;
        font-size: 2px;
      }
    }
  }

  .container {
    display: flex;
    gap: 16px;
    flex-grow: 1;

    .cardsContainer {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      gap: 16px;
      width: 30%;
    }
  }

  .dataContainer {
    display: flex;
    gap: 16px;
    justify-content: space-between;

    .dataMappingContainer {
      padding: 8px;
      display: flex;
      flex-direction: column;
      gap: 24px;
      width: 100%;
    }
  }

  .cta_container {
    position: absolute;
    left: 0;
    width: 100%;
  }

  .detailsCard {
    background-color: var(--ds_background_100) !important;
  }

  .loadHead {
    @include skeleton;

    &::after {
      width: 576%;
      background: linear-gradient(
        110deg,
        rgba(255, 255, 255, 0) 20%,
        rgba(255, 255, 255, 0.2) 50%,
        rgba(255, 255, 255, 0) 80%
      );
      animation-delay: 0.59s;
    }
  }

  .loadSubhead {
    @include skeleton;

    &::after {
      width: 346%;
      background: linear-gradient(
        110deg,
        rgba(255, 255, 255, 0) 20%,
        rgba(255, 255, 255, 0.2) 50%,
        rgba(255, 255, 255, 0) 80%
      );
      animation-delay: 0.6s;
    }
  }

  .loadSelect {
    @include skeleton;

    &::after {
      width: 382%;
      background: linear-gradient(
        110deg,
        rgba(255, 255, 255, 0) 20%,
        rgba(255, 255, 255, 0.2) 50%,
        rgba(255, 255, 255, 0) 80%
      );
      animation-delay: 0.72s;
    }
  }

  @keyframes shine {
    from {
      transform: translateX(-100%);
      visibility: visible;
    }
    to {
      transform: translateX(100%);
      visibility: visible;
    }
  }
}
