@import "./theme.scss";

.container {
  background: var(--backgroundPaper);
  flex-grow: 1;
  display: flex;

  .secondContainer {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }

  h3,
  p,
  h4 {
    margin: 0;
    font-family: "Geist";
  }
  p {
    font-size: 14px;
  }
  h3 {
    font-weight: 700;
  }

  ::selection {
    background: var(--primaryMain);
    color: black;
  }
}
