@import '../../../theme.scss';

.container{
  padding: 10px;
  border-radius: 4px;
  background: var(--backgroundDefault);
  display: flex;
  color: var(--textPrimary);
  font-size: 15px;
  gap:8px;
}

.tab {
  padding: 5px 40px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  gap: 15px;
  cursor: pointer;


  &:hover{
    background:var(--otherDivider);
  }
  
  svg {
    color: var(--textPrimary);
    width: 18px;
    height: 18px;
    padding-top: 5px;
    padding-bottom: 5px;
  }
}

.selected {
  background: var(--selectedTabBackground);
}


.title{
  white-space: nowrap;
}