@import "../../../../theme.scss";

.articleRowContainer {
  padding: 16px 24px;
  padding-bottom: 20px;
  font-family: "GeistMono";
  flex-grow: 1;
  display: flex;
  height: fit-content;
  align-items: center;
  border-radius: 8px;
  background: var(--ds_gray_100);
  cursor: pointer;
  gap: 8px;

  .topicName {
    width: 70%;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    color: var(--ds_gray_1000);
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  .topicData {
    width: 15%;
    color: var(--ds_gray_600);
    font-family: "GeistMono";
    font-size: 12px;
    font-weight: 400;
    line-height: 150%;
    display: flex;
    align-items: center;
    gap: 4px;
  }

  .suggestedArticle {
    width: 15%;
    color: var(--ds_gray_600);
    font-family: "GeistMono";
    font-size: 12px;
    font-weight: 400;
    line-height: 150%;
    display: flex;
    align-items: center;
    gap: 4px;
    padding: 0 16px;
  }
}
