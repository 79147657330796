@import "../../../theme.scss";

.performance {
  display: flex;
  gap: 1px;
  width: 100%;

  .performanceItem {
    height: 6px;
    background: var(--light_gray);
  }

  .red {
    background: var(--ds_red_700);
  }

  .yellow {
    background: var(--geist_brand_700);
  }

  .green {
    background: var(--ds_green_900);
  }
}
