@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Sora:wght@100;200;300;400;500;600;700;800&display=swap");

body {
  margin: 0;
  font-family: "Sora", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  font-family: "Sora", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html,
body,
#root,
#main {
  height: 100%;
}

#root,
#main {
  display: flex;
  height: fit-content;
}

#geist-ui-dropdown {
  div.select-dropdown {
    div.option {
      transition: all 0.3s;
      &:hover {
        span {
          color: #0a0a0a;
        }
      }
    }
  }
}


.dropdown{
  z-index: 10000000000000000 !important;
}
.radix-themes{
  width: 100%;
}