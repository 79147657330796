@import "../../theme.scss";

.barDataRowContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  flex-grow: 1;

  .topicName {
    color: var(--ds_gray_600);
    font-family: "GeistMono";
    font-size: 12px;
    line-height: 150%;
  }

  .barDataContainer {
    width: 100%;
    height: 26px;
    background-color: var(--ds_gray_400);
    border-radius: 4px;
    display: flex;
    align-items: center;
    gap: 10px;

    .per {
      border-radius: 4px;
      width: 0;
      background-color: var(--geist_violet_light);
      height: 100%;
      transition: width 1s ease-out;

      &.green {
        background-color: var(--geist_color_cyan);
      }

      &.yellow {
        background-color: var(--ds_yellow_700);
      }

      &.red {
        background-color: var(--ds_red_900);
      }
    }

    h4 {
      color: var(--ds-gray-alpha-1000);
      font-family: "GeistMono";
      font-size: 12px;
      font-weight: 400;
      line-height: 150%;
    }
  }
}
