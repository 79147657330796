@import "../../../theme.scss";

.circleDiv {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 0;
  width: 100%;

  .circleCont {
    margin: 10px auto;
    width: fit-content;
    width: 100%;
    margin: 0;

    .circle {
      stroke: var(--ds_gray_400);
      fill: none;
      stroke-width: 2.8;
      stroke-linecap: round;
      animation: progress 1s ease-out forwards;
    }

    &.active {
      position: absolute;
      top: 0;
      right: 0;
      width: fit-content;

      .circle {
        stroke: var(--geist_violet_light);
      }
    }
  }

  .biggerContainer {
    position: absolute;
    width: 135%;
    height: 135%;
    top: -12%;

    .circleCont {
      .circle {
        stroke-width: 2;
      }

      &.active {
        .circle {
          stroke: var(--geist_color_cyan);
        }
      }
    }
  }
  .smallerContainer {
    position: absolute;
    width: 65%;
    height: 65%;
    top: 20%;

    .circleCont {
      .circle {
        stroke-width: 4;
      }

      &.active {
        .circle {
          stroke: var(--geist_success);
        }
      }
    }
  }

  .detailsContianer {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: "GeistMono";
    gap: 4px;

    .number {
      color: var(--ds_gray_1000);
      font-size: 20px;
      font-weight: 700;
      line-height: 32px;
    }

    .label {
      color: var(--ds_gray_1000);
      font-size: 12px;
      font-weight: 400;
      line-height: 150%;
    }
  }
}

@keyframes progress {
  0% {
    stroke-dasharray: 0 100;
  }
}
