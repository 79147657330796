$themes: (
  backgroundDefault: (
    darkTheme: #121212,
    lightTheme: #dbdfe3,
  ),
  backgroundPaper: (
    darkTheme: #121212,
    lightTheme: #caccd0,
  ),
  otherDivider: (
    darkTheme: #2e3843,
    lightTheme: #c1c1c1,
  ),
  // textSecondary:(
  //         darkTheme: #B6B7B9,
  //         lightTheme: #242424
  // ),
  // textPrimary:(
  //         darkTheme: #ECECED,
  //         lightTheme: #181818
  // ),
  // infoMain:(
  //         darkTheme: #2F8CED,
  //         lightTheme: #2F8CED
  // ),
  // infoLight:(
  //         darkTheme: #74ACE0,
  //         lightTheme: #74ACE0
  // ),
  selectedTabBackground:
    (
      darkTheme: #364454,
      lightTheme: #dbdfe3,
    ),
  primaryMain: (
    darkTheme: #fed942,
    lightTheme: #fed942,
  ),
  primaryMainFade: (
    darkTheme: rgba(254, 217, 66, 0.2),
    lightTheme: rgba(254, 217, 66, 0.2),
  ),
  primaryContrast: (
    darkTheme: #292929,
    lightTheme: #e0e0e0,
  ),
  infoMain: (
    darkTheme: #2f8ced,
    lightTheme: #2f8ced,
  ),
  infoLight: (
    darkTheme: #74ace0,
    lightTheme: #74ace0,
  ),
  textPrimary: (
    darkTheme: #ececed,
    lightTheme: #181818,
  ),
  textSecondary: (
    darkTheme: #b6b7b9,
    lightTheme: #242424,
  ),
  textDisabled: (
    darkTheme: #86888b,
    lightTheme: #686868,
  ),
  errorMain: (
    darkTheme: #fe5050,
    lightTheme: #fe5050,
  ),
  errorLight: (
    darkTheme: #ef8784,
    lightTheme: #ef8784,
  ),
  warningMain: (
    darkTheme: #d89618,
    lightTheme: #d89618,
  ),
  warningLight: (
    darkTheme: #f5dcab,
    lightTheme: #f5dcab,
  ),
  //     backgroundPaper:(
  //         darkTheme: #161C23,
  //         lightTheme: #caccd0
  //     ),
  //     backgroundDefault:(
  //         darkTheme: #0D1116,
  //         lightTheme: #dbdfe3
  //     ),
  actionDisabled:(
      darkTheme: #55585b,
      lightTheme: #55585b,
    ),
  actionActive: (
    darkTheme: #959699,
    lightTheme: #959699,
  ),
  otherUser: (
    darkTheme: #d89618,
    lightTheme: #d89618,
  ),
  otherSnackbar: (
    darkTheme: #ffffff,
    lightTheme: #434343,
  ),
  otherBackdrop: (
    darkTheme: #202428,
    lightTheme: #e0e0e0,
  ),
  successGreen: (
    darkTheme: #59ce8f,
    lightTheme: #59ce8f,
  ),
  newBg: (
    darkTheme: #27303a,
    lightTheme: #e0e0e0,
  ),
  ds_gray_1000: (
    darkTheme: #ededed,
    lightTheme: #ededed,
  ),
  ds_gray_2000: (
    darkTheme: #d4d3d3,
    lightTheme: #d4d3d3,
  ),
  ds_gray_600: (
    darkTheme: #878787,
    lightTheme: #878787,
  ),
  ds_gray_700: (
    darkTheme: #8f8f8f,
    lightTheme: #8f8f8f,
  ),
  ds_gray_900: (
    darkTheme: #a1a1a1,
    lightTheme: #a1a1a1,
  ),
  accents_3: (
    darkTheme: #8f8f8f,
    lightTheme: #8f8f8f,
  ),
  ds_background_100: (
    darkTheme: #0a0a0a,
    lightTheme: #0a0a0a,
  ),
  ds_background_1000: (
    darkTheme: #101011,
    lightTheme: #101011,
  ),
  accents_5: (
    darkTheme: #454545,
    lightTheme: #454545,
  ),
  ds_gray_200: (
    darkTheme: #1f1f1f,
    lightTheme: #1f1f1f,
  ),
  ds_gray_400: (
    darkTheme: #2e2e2e,
    lightTheme: #2e2e2e,
  ),
  ds_gray_100: (
    darkTheme: #1a1a1a,
    lightTheme: #1a1a1a,
  ),
  accents_8: (
    darkTheme: #8f8f8f,
    lightTheme: #8f8f8f,
  ),
  geist_background: (
    darkTheme: #121212,
    lightTheme: #121212,
  ),
  geist_primary_background: (
    darkTheme: #111112,
    lightTheme: #111112,
  ),
  geist_color_primary_geist_foreground: (
    darkTheme: #fff,
    lightTheme: #fff,
  ),
  ds_yellow_600: (
    darkTheme: #ffe602,
    lightTheme: #ffe602,
  ),
  ds_yellow_900: (
    darkTheme: #383115,
    lightTheme: #383115,
  ),
  ds_yellow_700: (
    darkTheme: #fabe2a,
    lightTheme: #fabe2a,
  ),
  blue_200: (
    darkTheme: #10233d,
    lightTheme: #10233d,
  ),
  blue_900: (
    darkTheme: #52a8ff,
    lightTheme: #52a8ff,
  ),
  geist_violet_light: (
    darkTheme: #8a63d2,
    lightTheme: #8a63d2,
  ),
  ds_gray_alpha_100: (
    darkTheme: #ffffff0f,
    lightTheme: #ffffff0f,
  ),
  ds_gray_alpha_300: (
    darkTheme: #ffffff21,
    lightTheme: #ffffff21,
  ),
  accents_2: (
    darkTheme: #333333,
    lightTheme: #333333,
  ),
  ds_red_900: (
    darkTheme: #ff6166,
    lightTheme: #ff6166,
  ),
  ds_red_200: (
    darkTheme: #3c1618,
    lightTheme: #3c1618,
  ),
  ds_green_900: (
    darkTheme: #62c073,
    lightTheme: #62c073,
  ),
  ds_green_200: (
    darkTheme: #0f2e18,
    lightTheme: #0f2e18,
  ),
  geist_color_cyan: (
    darkTheme: #50e3c2,
    lightTheme: #50e3c2,
  ),
  ds_gray_alpha_1000: (
    darkTheme: #ffffffeb,
    lightTheme: #ffffffeb,
  ),
  gesit_cyan_dark: (
    darkTheme: #29bc9b,
    lightTheme: #29bc9b,
  ),
  gradient_1: (
    darkTheme: #ff1e56,
    lightTheme: #ff1e56,
  ),
  gradient_2: (
    darkTheme: #0096ff,
    lightTheme: #0096ff,
  ),
  giest_cyan: (
    darkTheme: #29bc9b,
    lightTheme: #29bc9b,
  ),
  cool_gray_200: (
    darkTheme: #e5e7eb,
    lightTheme: #e5e7eb,
  ),
  light_gray: (
    darkTheme: #7d7d7d,
    lightTheme: #7d7d7d,
  ),
  geist_error_dark: (
    darkTheme: #c50000,
    lightTheme: #c50000,
  ),
  geist_success: (
    darkTheme: #0070f3,
    lightTheme: #0070f3,
  ),
  ds_pink_600: (
    darkTheme: #b21a57,
    lightTheme: #b21a57,
  ),
  pastel_brown: (
    darkTheme: #ccb79c,
    lightTheme: #ccb79c,
  ),
  ds_red_700: (
    darkTheme: #e5484d,
    lightTheme: #e5484d,
  ),
  geist_brand_700: (
    darkTheme: #fabe2a,
    lightTheme: #fabe2a,
  ),
  ds_amber_900: (
    darkTheme: #ff990a,
    lightTheme: #ff990a,
  ),
  geist_sementic_border_border_card: (
    darkTheme: rgba(255, 255, 255, 0.09),
    lightTheme: rgba(255, 255, 255, 0.09),
  ),
  geist_black_background: (
    darkTheme: #191919,
    lightTheme: #191919,
  ),
  box_shadow: (
    darkTheme: rgba(0, 0, 0, 0.15),
    lightTheme: rgba(0, 0, 0, 0.15),
  ),
  geist_sementic_text_text_primary: (
    darkTheme: #d0d0d0,
    lightTheme: #d0d0d0,
  ),
);

@mixin styles($mode) {
  @each $key, $map in $themes {
    @each $prop, $color in $map {
      @if $prop == $mode {
        --#{$key}: #{$color};
      }
    }
  }
}

.main_app_container {
  display: flex;
  height: 100%;

  pre {
    font-family: monospace !important;
  }
}

.dark_mode {
  @include styles("darkTheme");
}

.quack-table {
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;
  tbody {
    min-height: 200px;
    width: 100%;
  }
  .headerRow {
    background: var(--backgroundPaper);
    border: 1px solid var(--otherDivider);
    th {
      padding: 16px;
    }
  }

  .split {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    .split {
      display: flex;
      flex-direction: row;
      height: 100%;
    }

    .gutter {
      background-color: var(--otherDivider);
      background-repeat: no-repeat;
      background-position: 50%;
      width: 3px !important;
      min-width: 3px;
    }

    .gutter.gutter-horizontal {
      /* background: var(--infoLight); */
      cursor: col-resize;
    }
  }

  .expandableRow {
    height: 0;
    border-bottom: 1px solid transparent;
    transition: all 0.4s;
    box-sizing: border-box;
    td {
      padding: unset;
      position: relative;
    }
  }

  .expandableRow-expanded {
    height: 300px;
    border-bottom: 1px solid var(--otherDivider);
  }

  .row {
    border-bottom: 1px solid var(--otherDivider);
    cursor: pointer;
    td {
      color: var(--textPrimary);
      text-align: start;
      white-space: pre;
      max-width: 200px;
      text-overflow: ellipsis;
      font-size: 11px;
      overflow: hidden;
      white-space: nowrap;
      button {
        padding: 4px 6px !important;
      }
      &.arrow {
        width: 20px !important;
        padding: 16px 4px;
        text-align: center;
        svg {
          transform: rotate(-90deg);
          color: var(--textSecondary);
          transition: all 0.2s;
          height: 16px;
          width: 16px;
        }
      }
      &.method,
      &.time,
      &.status {
        width: 120px !important;
        padding: 16px 8px;
        color: var(--textSecondary);
      }
      &.success {
        color: var(--infoLight) !important;
      }
      &.error {
        color: var(--errorLight) !important;
      }
      &.main {
        font-weight: 500;
        max-width: 300px;
        font-size: 12px !important;
        width: 300px;
        padding: 0 12px;
        position: relative;
        cursor: pointer;
        label {
          cursor: pointer;
          width: 100%;
          display: flex;
          padding: 16px 4px;
          overflow-x: scroll;
        }
      }
    }
  }

  .row-expanded {
    td {
      &.arrow {
        svg {
          transform: rotate(0);
        }
      }
    }
  }

  .consoleRow {
    border-bottom: 1px solid var(--otherDivider);
    cursor: pointer;
    td {
      color: var(--textSecondary);
      text-align: start;
      white-space: pre;
      max-width: 200px;
      text-overflow: ellipsis;
      font-size: 11px;
      overflow: hidden;
      white-space: nowrap;
      button {
        padding: 4px 6px !important;
      }
      &.arrow {
        width: 20px !important;
        padding: 16px 4px;
        text-align: center;
        svg {
          // transform: rotate(${isExpanded ? '0' : '-90deg'});
          transform: rotate(-90deg);
          color: var(--textSecondary);
          transition: all 0.2s;
          height: 16px;
          width: 16px;
        }
      }
      &.method,
      &.time,
      &.status {
        width: 120px !important;
        padding: 16px 8px;
        color: var(--textSecondary);
      }
      &.success {
        color: var(--infoLight) !important;
      }
      &.error {
        color: var(--errorLight) !important;
      }
      &.main {
        &::before {
          content: "";
          position: absolute;
          left: 5px;
          top: 5px;
          bottom: 5px;
          display: block;
          width: 2px;
          z-index: 2;
        }
        font-weight: 500;
        max-width: 300px;
        font-size: 12px !important;
        width: 300px;
        padding: 12px;
        position: relative;
        cursor: pointer;
        text-overflow: unset !important;
        white-space: unset !important;
        word-break: break-all;
        label {
          cursor: pointer;
          width: 100%;
          display: flex;
          padding: 16px 4px;
          overflow-x: scroll;
        }
      }
    }
  }

  .consoleRow-expanded {
    td {
      &.arrow {
        svg {
          transform: rotate(0);
        }
      }
    }
  }

  .warn {
    td {
      &.main {
        color: var(--warningMain);
        &::before {
          background: var(--warningMain);
        }
      }
    }
  }

  .log {
    td {
      &.main {
        color: var(--textSecondary);
        &::before {
          background: unset;
        }
      }
    }
  }

  .error {
    td {
      &.main {
        color: var(--errorMain);
        &::before {
          background: var(--errorMain);
        }
      }
    }
  }

  .info {
    td {
      &.main {
        color: var(--infoMain);
        &::before {
          background: var(--infoMain);
        }
      }
    }
  }

  .collapsable {
    padding: 8px;
    display: flex;
    flex-direction: column;
    h4 {
      color: var(--textPrimary);
      margin: 0;
    }
    > div {
      overflow: scroll;
    }
  }
}

.expanded-icon,
.collapsed-icon {
  svg {
    color: var(--textSecondary) !important;
  }
}

.array-key {
  color: gray !important;
}

.variable-value {
  div {
    color: var(--infoMain) !important;
  }
}

.copy-icon {
  svg {
    color: var(--infoLight) !important;
  }
}

.node-ellipsis {
  color: var(--warningMain) !important;
}

.rr-player {
  background: var(--backgroundDefault) !important;
}

.rr-controller {
  background: var(--backgroundDefault) !important;
  color: var(--textPrimary);
}

.rr-progress {
  border-top-color: var(--backgroundDefault) !important;
  border-bottom-color: var(--backgroundDefault) !important;
}

.rr-timeline {
  width: 100% !important;
}
.rr-controller__btns > * {
  color: var(--textPrimary) !important;
}

.rr-controller__btns > button > svg {
  color: var(--textPrimary) !important;
  fill: var(--textPrimary) !important;
}
.rr-timeline__time {
  color: var(--textPrimary) !important;
}

.switch {
  display: none !important;
  .label {
    display: none !important;
  }
}

::selection {
  background: var(--primaryMain) !important;
  color: black !important;
}

.samplingTabs {
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  color: var(--ds_gray_1000);
  font-family: "GeistMono";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;

  .scroll-container {
    padding-left: 40px !important;
  }

  .content {
    padding-top: 0 !important;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow-y: auto;
    height: 200px;
  }
}

@mixin skeleton {
  position: relative;
  overflow: hidden;

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      110deg,
      rgba(255, 255, 255, 0) 30%,
      rgba(255, 255, 255, 0.2) 50%,
      rgba(255, 255, 255, 0) 70%
    );
    animation: shine 1s linear infinite;
    visibility: hidden;
  }
}

@mixin skeletonCardShape {
  border-radius: 6px;
  background-color: var(--ds_gray_100) !important;
  color: transparent;
}

@font-face {
  font-family: "GeistMono";
  src: url("./assets/fonts/geistMono/GeistMono-Thin.woff2") format("woff2"),
    url("./assets/fonts/geistMono/GeistMono-Thin.woff") format("woff");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "GeistMono";
  src: url("./assets/fonts/geistMono/GeistMono-UltraLight.woff2")
      format("woff2"),
    url("./assets/fonts/geistMono/GeistMono-UltraLight.woff") format("woff");
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "GeistMono";
  src: url("./assets/fonts/geistMono/GeistMono-Light.woff2") format("woff2"),
    url("./assets/fonts/geistMono/GeistMono-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "GeistMono";
  src: url("./assets/fonts/geistMono/GeistMono-Regular.woff2") format("woff2"),
    url("./assets/fonts/geistMono/GeistMono-Regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "GeistMono";
  src: url("./assets/fonts/geistMono/GeistMono-Medium.woff2") format("woff2"),
    url("./assets/fonts/geistMono/GeistMono-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "GeistMono";
  src: url("./assets/fonts/geistMono/GeistMono-SemiBold.woff2") format("woff2"),
    url("./assets/fonts/geistMono/GeistMono-SemiBold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "GeistMono";
  src: url("./assets/fonts/geistMono/GeistMono-Bold.woff2") format("woff2"),
    url("./assets/fonts/geistMono/GeistMono-Bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "GeistMono";
  src: url("./assets/fonts/geistMono/GeistMono-Black.woff2") format("woff2"),
    url("./assets/fonts/geistMono/GeistMono-Black.woff") format("woff");
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "GeistMono";
  src: url("./assets/fonts/geistMono/GeistMono-UltraBlack.woff2")
      format("woff2"),
    url("./assets/fonts/geistMono/GeistMono-UltraBlack.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Geist";
  src: url("./assets/fonts/geist/Geist-Thin.woff2") format("woff2"),
    url("./assets/fonts/geist/Geist-Thin.woff") format("woff");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Geist";
  src: url("./assets/fonts/geist/Geist-UltraLight.woff2") format("woff2"),
    url("./assets/fonts/geist/Geist-UltraLight.woff") format("woff");
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Geist";
  src: url("./assets/fonts/geist/Geist-Light.woff2") format("woff2"),
    url("./assets/fonts/geist/Geist-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Geist";
  src: url("./assets/fonts/geist/Geist-Regular.woff2") format("woff2"),
    url("./assets/fonts/geist/Geist-Regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Geist";
  src: url("./assets/fonts/geist/Geist-Medium.woff2") format("woff2"),
    url("./assets/fonts/geist/Geist-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Geist";
  src: url("./assets/fonts/geist/Geist-SemiBold.woff2") format("woff2"),
    url("./assets/fonts/geist/Geist-SemiBold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Geist";
  src: url("./assets/fonts/geist/Geist-Bold.woff2") format("woff2"),
    url("./assets/fonts/geist/Geist-Bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Geist";
  src: url("./assets/fonts/geist/Geist-Black.woff2") format("woff2"),
    url("./assets/fonts/geist/Geist-Black.woff") format("woff");
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Geist";
  src: url("./assets/fonts/geist/Geist-UltraBlack.woff2") format("woff2"),
    url("./assets/fonts/geist/Geist-UltraBlack.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

.debug {
  background: red;
}

.debug-b {
  border: 1px solid red;
}
