.container{
    
    display:flex;
    -webkit-box-flex: 1;
    flex-grow: 1;
    -webkit-box-align: stretch;
    align-items: stretch;
    height: 100%;
    overflow: hidden;
    position: relative;
    
}

.header{
    
    display: flex;
    align-items: center;
    justify-content: space-between;
    div{
        display: flex;

        align-items: center;
        gap:24px;
    }
}

.red{
    color:#E5484D;
}

.yellow{
color:#FFC53D;
}

.green{
    color:#30A46C;
}


.bodyContainer{
    padding: 28px;
    display: flex;
    flex-direction: column;
    gap:24px
}

.cardHeader{
    display: flex;
    align-items: center;
    gap:60px;
    

    justify-content: space-between;
    div{
        gap: 4px;
        display: flex;
        align-items: center;
    }
}

.topContainer{
    display: flex;
    gap:24px;    
}


.body{
    flex-grow: 1;
    padding:24px;
    width: 100%;
}

.sidePanel{
    width: 400px;
    min-width: 400px;
    max-width: 400px;
    flex-grow: 1;    
    transition: all .5s; 
    display: flex;
    transform: none;
    flex-direction: column;
}

.sidePanelContainer{
    border-left: 1px solid var(--accents_5);
    
    flex-grow: 1;
}

.sidePanelHeader{
    padding:  10px 8px;
    display: flex;
    align-items: center;
}

.sidePanelTitle{
    background:var(--color-panel);
    padding:8px 24px;
    border-bottom: 1px solid var(--accents_5);
}

.collapsed{
    width: 0px;
    min-width: 0px;
    max-width: 0px;
    transform: none;
}

.metricCard{
    display: flex;
    flex-direction: column;
    gap:20px
}
.no_gap{
    gap:0px
}

.sidePanelGrid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);

    >div {
        padding: 18px;
        border-bottom: 1px solid var(--accents_5);
    }

    >div:nth-child(2n + 1) {
        border-right: 1px solid var(--accents_5);
    }

}


.textContainer{
    display: flex;
    gap: 4px;
    align-items: center;
}