@import "../../../../theme.scss";

.container {
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin: 0 auto;
  margin-top: 24px;
}

.messageContainer {
  display: flex;
  gap: 16px;

  img {
    width: 28px;
    height: 28px;
    border-radius: 8px;
  }

  &.withLabel {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 4px;

    .label {
      color: var(--ds_gray_600);
      /* Text/12/Normal/400 */
      font-family: Geist;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 12px; /* 100% */
    }
  }
}

.message {
  padding: 16px;
  color: var(--cool_gray_200);
  font-family: Geist;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  border-radius: 16px;
  align-items: flex-start;
  width: fit-content;
  position: relative;
  background: var(--ds_gray_100);
  text-align: left;
  white-space: break-spaces;
  transition: all 0.3s ease;

  &.loading {
    color: transparent;
    @include skeleton;
  }

  &.draft {
    border: 2px solid var(--ds_gray_600);
    padding: 14px;
  }

  .draft {
    transition: all 0.3s ease;
    position: absolute;
    bottom: -26px;
    right: 16px;
    background: var(--ds_gray_600);
    color: var(--ds_gray_100);
    padding: 6px 8px;
    border-radius: 4px;
    font-size: 14px;
    line-height: 14px;

    &.hide {
      opacity: 0;
    }
  }
}

@keyframes shine {
  from {
    transform: translateX(-100%);
    visibility: visible;
  }
  to {
    visibility: visible;
    transform: translateX(100%);
  }
}
