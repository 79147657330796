@import "../../theme.scss";

.container {
  display: flex;
  flex-direction: column;
  color: var(--textSecondary);
  font-size: 12px;
  font-weight: 300;
  padding: 6px;
  gap: 6px;

  > span {
    display: flex;
    gap: 4px;
    b {
      color: var(--textPrimary);
    }
    label {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .divider {
    background: var(--otherDivider);
    height: 1px;
    margin: 4px 0;
  }
}

.customLabel {
  text-overflow: unset !important;
  white-space: unset !important;
  word-break: break-all;
}
