@import "../../theme.scss";

.mainContainer {
  display: flex;
  background: var(--geist_background);
  height: calc(100vh - 64px);
  font-family: Geist;
  display: flex;

  .articleContainer {
    overflow-y: auto;
    flex-grow: 2.5;
    padding: 24px 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    transition: all 0.2s ease-in-out;
    max-width:800px;


    .article {
      border-radius: 16px;
      background: var(--ds_gray_200);
      padding: 24px 24px 40px 40px;
      width: 100%;
      transition: all 0.2s ease-in-out;

      .content {
        padding: 8px;
        display: flex;
        gap: 24px;
        flex-direction: column;

        .copyBadgeContainer {
          align-self: flex-end;
        }

        p{
          white-space: break-spaces;
        }
      }

      &.empty {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;

        .emptyState {
          display: flex;
          flex-direction: column;
          align-items: center;
          font-size: 18px;

          span {
            font-size: 22px;
          }
        }
      }

      pre:not([class]) {
        display: none;
      }

      &.loading {
        color: transparent;
        height: 100%;
        @include skeleton;
      }
    }

    &.compare {
      padding: 24px 40px;

      .article {
        padding: 18px;
      }
    }
  }

  .brainContainer {
    max-height: 100%;
    flex-grow: 1;
    background: var(--ds_gray_100);
    border-left: 1px solid var(--geist_sementic_border_border_card);

    display: flex;
    flex-direction: column;
    align-items: flex-start;

    min-width: 30%;
    max-width: 35%;

    .header {
      width: 100%;
      border-bottom: 1px solid var(--geist_sementic_border_border_card);
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 24px;
      padding-bottom: 24px;

      .brainContainerHeader {
        padding: 24px;
        padding-bottom: 0;
        margin: 0;
        color: var(--geist_sementic_text_text_primary);
        font-family: Geist;
        font-size: 20px;
        font-weight: 600;
        line-height: 32px;
        overflow-wrap: break-word;
      }

      .coverageContainer {
        display: flex;
        width: 100%;
        padding: 0px 40px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 10px;

        .currentCoverage {
          color: var(--geist_sementic_text_text_primary);
          font-size: 32px;
          font-weight: 600;
          line-height: 40px;
        }

        .opportunityCoverage {
          color: var(--ds_green_900);
          font-size: 13px;
          font-weight: 400;
          line-height: 16px;
        }

        .coverageExplanation {
          color: var(--ds_gray_900);
          font-size: 13px;
          font-weight: 400;
          line-height: 16px;
        }
      }
    }

    .editorsContainer {
      display: flex;
      flex-direction: column;
      width: 100%;
      overflow-y: auto;
      .editContainer {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 12px;
        padding: 16px 40px;

        h5 {
          color: var(--ds_gray_900);
          font-size: 13px;
          font-weight: 400;
          line-height: 16px;
          margin: 0;
        }

        .opportunityList {
          width: 100%;
          display: flex;
          flex-direction: column;
          gap: 40px;

          .opportunityItem {
            display: flex;
            align-items: center;
            background-color: transparent;
            transition: none;
            gap: 16px;
            cursor: pointer;

            .checkbox {
              margin-right: 12px;
              width: 24px;
              height: 24px;
              appearance: none;
              border: 2px solid black;
              border-radius: 4px;
              background-color: white;

              display: flex;
              align-items: center;
              justify-content: center;
              cursor: pointer;

              &:disabled{
                opacity: 0.7;
                cursor:not-allowed
              }

              &:checked {
                background-color: white;
              }

              &:checked::before {
                content: "✔";
                color: black;
                font-size: 16px;
              }
            }

            label {
              color: var(--ds_gray_1000);
              text-align: left;
              font-size: 14px;
              font-weight: 400;
              line-height: 20px;
              cursor: pointer;
            }
          }
        }

        textarea {
          width: 100%;
          border-radius: 16px;
          border: 1px solid var(--geist_sementic_border_border_card);
          background: var(--ds_gray_200);
          padding: 16px;
          resize: none;

          &::placeholder {
            color: var(--ds_gray_600);
          }
        }
      }
    }

    .improveContainer {
      padding: 24px;
      width: 100%;
      border-top: 1px solid var(--geist_sementic_border_border_card);
      background: var(--ds_gray_100);
      button.improveButton {
        all: unset;
        box-sizing: border-box;
        width: 100%;
        border-radius: 6px;
        border: 1px solid var(--ds_gray_400);
        background: var(--ds_gray_100);
        display: flex;
        padding: 10px 12px;
        justify-content: center;
        align-items: center;
        color: var(--ds_gray_700);
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        transition: all 0.2s ease-in-out;

        &.active {
          cursor: pointer;
          border-color: var(--ds_gray_1000);
          background: var(--ds_gray_1000);
          color: black;
        }
      }
    }

    &.loading {
      color: transparent;
      @include skeleton;

      &::after {
        width: 200%;
      }
    }
  }
}

@keyframes shine {
  from {
    transform: translateX(-100%);
    visibility: visible;
  }
  to {
    visibility: visible;
    transform: translateX(100%);
  }
}
