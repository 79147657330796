@import "../../theme.scss";

div.selectTimeFilter {
  width: 18% !important;
  align-self: flex-end;
  font-size: 16px;

  div {
    svg {
      color: var(--ds_gray_1000);
    }
  }

  &.disabled {
    background-color: var(--ds_background_100);
    span {
      div {
        span {
          color: var(--ds_gray_1000);
        }
      }
    }
  }
}

.bigSelect {
  border-radius: 8px !important;
  background: var(--ds_gray_200) !important;
  color: var(--ds_gray_1000) !important;
  border: none !important;

  height: 50px !important;
  min-width: 250px !important;

  font-family: Geist !important;
  font-size: 24px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: 24px !important;
  padding: 8px 16px !important;

  -moz-appearance: none !important; /* Firefox */
  -webkit-appearance: none !important; /* Safari and Chrome */
  appearance: none !important;

  span {
    font-family: Geist !important;
    font-size: 14px !important;
    font-style: normal !important;
    font-weight: 700 !important;
    line-height: 24px !important;
  }

  div {
    justify-content: flex-start !important;
    div div {
      background: var(--ds_gray_2000) !important;
      color: white;
      div {
        background: transparent !important;
      }
    }
  }
}

span {
  span {
    color: white !important;
  }
}
