@import "../../../theme.scss";

.container {
  padding: 40px 30px;
  padding-bottom: 0;
  display: flex;
  gap: 16px;
  height: 100%;
  overflow-y: scroll;

  scroll-behavior: smooth;

  .leftPanel {
    max-width: 300px;
    min-width: 300px;
    flex-grow: 1;
    position: sticky;
    top: 0;
  }

  .rightPanel {
    flex-grow: 4;
    display: flex;
    flex-direction: column;
    gap: 36px;
    padding-bottom: 40px;
    overflow: auto;
  }
}

.title {
  display: flex;
  align-items: center;
}

.clickable {
  cursor: pointer;
  padding: 4px;
  width: fit-content;
  border-radius: 6px;
  &:hover {
    background-color: #1f1f1f;
  }
}

.tabs {
  header > div:nth-child(2) {
    border-left: 1px solid #333 !important;
    border-bottom: 0 !important;
    align-items: baseline !important;
    flex-direction: column !important;
    font-size: 14px;
    font-family: "Geist";
    div::after {
      bottom: 0;
      left: -16px;
      right: 0;
      width: 2px;
      height: 100%;
    }
  }
}

.dot {
  display: flex;

  > span {
    border-radius: 2px !important;
    margin-right: 10px;
    display: flex;
    align-items: center;
    gap: 10px;
  }
}

.red {
  > span:first-child {
    background-color: #ff6166 !important;
  }
}

.orange {
  > span:first-child {
    background-color: #ff990a !important;
  }
}

.green {
  > span:first-child {
    background-color: #0ac7b4 !important;
  }
}

.hidden {
  > span:first-child {
    display: none;
  }
}

.itemsContainer {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 16px;

  &.loading {
    margin-top: 38px;

    div {
      @include skeleton;
      color: transparent;
    }
  }
}

.emptyItem {
  padding: 48px 24px;
  background: #1f1f1f;
  border-radius: 12px;
  border: 1px solid #333333;
  display: flex;
  text-align: center;
  justify-content: center;
  color: #a1a1a1;
  font-family: monospace;
}

.item {
  padding: 24px;
  background: #1f1f1f;
  border-radius: 12px;
  border: 1px solid #333333;
  display: flex;
  gap: 20px;

  .badge {
    width: 180px;
  }

  &:hover {
    cursor: pointer;
    border: 1px solid #5f5f5f;
  }

  .label {
    width: 60%;
    * {
      font-size: 16px;
      font-family: "Geist";
    }
  }

  .subType {
    width: 400px;
    * {
      color: #a1a1a1;
      font-family: monospace;
    }
  }
}

.drawer {
  height: 100%;
  min-width: 720px;
  max-width: 900px;
  background-color: var(--geist_background);

  .drawerHeader {
    display: flex;
    padding: 16px;
    align-items: center;
    gap: 24px;
    align-self: stretch;
    border-bottom: 1px solid var(--geist_sementic_border_border_card);
  }

  .drawerContent {
    height: 100%;

    .header {
      display: flex;
      flex-direction: column;
      padding: 40px;
      padding-bottom: 24px;
      align-items: flex-start;
      gap: 16px;

      .headerDetails {
        display: flex;
        padding: 0px 2px;
        flex-direction: column;
        align-items: flex-start;
        gap: 4px;

        h3 {
          margin: 0;
          overflow: hidden;
          color: var(--ds_gray_1000, #ededed);
          text-overflow: ellipsis;
          font-family: Geist;
          font-size: 24px;
          font-weight: 700;
          line-height: 32px;
          text-align: left;
        }

        .line {
          display: flex;

          > span.dot {
            border-radius: 2px !important;
            margin-right: 10px;
            font-size: 14px;
            display: flex;
            align-items: center;
          }

          p {
            margin: 0;
            color: var(--ds_gray_900);
            font-family: "GeistMono";
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
          }
        }
      }
    }

    .content {
      display: flex;
      padding: 40px 48px;
      flex-direction: column;
      align-items: flex-start;
      gap: 16px;

      .top {
        display: flex;
        justify-content: space-between;
        width: 100%;
        margin-bottom: 16px;

        p {
          margin: 0;
          color: var(--ds_gray_900);
          font-family: Geist;
          font-size: 14px;
          font-weight: 500;
          line-height: 20px;
        }
      }

      .transaction {
        display: flex;
        padding: 24px 24px 40px 40px;
        flex-direction: column;
        align-items: flex-start;
        border-radius: 16px;
        background: var(--geist_black_background);
        box-shadow: 0px 4px 54px 0px var(--box_shadow);
        gap: 12px;

        .copy {
          cursor: pointer;
          display: flex;
          padding: 4px 12px;
          justify-content: center;
          align-items: center;
          gap: 10px;
          border-radius: 6px;
          border: 1px solid var(--ds_gray_400);
          background: var(--ds_gray_100);
          color: var(--ds_gray_1000);
          text-align: center;

          font-family: Geist;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px;
          align-self: flex-end;

          transition: all 0.2s ease-in-out;

          &.copied {
            color: var(--gesit_cyan_dark);
          }
        }

        h5 {
          width: 100%;
          margin: 0;
          text-align: left;
          color: var(--ds_gray_1000);
          font-family: Geist;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;
        }
      }

      .buttons {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        gap: 12px;
        position: relative;

        button {
          display: flex;
          width: 140px;
          height: 40px;
          padding: 0px 12px;
          justify-content: center;
          align-items: center;
          gap: 10px;
          flex-shrink: 0;
          border-radius: 6px;
          border: 1px solid var(--ds_gray_400);
          background: var(--ds_gray_100);
          color: var(--ds_gray_1000);
          font-family: Geist;
          font-size: 14px;
          font-weight: 500;
          line-height: 20px;

          transition: all 0.2s ease-in-out;

          &.verify {
            background-color: var(--ds_gray_1000);
            color: var(--ds_background_100);

            &.verified {
              background-color: var(--ds_yellow_900);
              color: var(--geist_brand_700);
            }
          }

          &:hover:not(:disabled) {
            background-color: var(--accents_3);
            border: 1px solid var(--ds_gray_100);
            color: var(--ds_gray_100);
          }

          &.loading {
            @include skeleton;
            color: transparent;
          }
        }

        span {
          position: absolute;
          bottom: -20px;
          right: 0;
          color: var(--ds_gray_900);
          font-family: Geist;
          font-size: 12px;
          font-weight: 400;
          line-height: 12px;
        }
      }

      .articles {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 10px;

        .article {
          cursor: pointer;
          display: flex;
          padding: 8px;
          align-items: center;
          border-radius: 4px;
          background: var(--primaryContrast);
          gap: 10px;
          color: var(
            --geist-sementic-text-text-action-tertiary,
            rgba(255, 255, 255, 0.92)
          );
          font-family: Geist;
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;

          svg {
            width: 16px;
            height: 16px;
            color: var(--ds_gray_700) !important;
          }
        }
      }
    }

    &.loading {
      @include skeleton;
    }
  }
}

@keyframes shine {
  from {
    transform: translateX(-100%);
    visibility: visible;
  }
  to {
    visibility: visible;
    transform: translateX(100%);
  }
}
