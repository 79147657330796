.container{
    display: flex;
    flex-direction: column;
    gap: 8px;
    flex-grow: 1; 
    padding: 12px 8px;
    *{
        color: var(--ds_gray_900);
    }
}

.card{
    background:#23211B !important;
    padding:0 !important;
    border-radius: 8px !important;
    border:0 !important;
    border-left: 1px solid #4A3D1E !important;
    position: relative !important;

    
    >div{
        display: flex !important;
        flex-direction: column !important;
        gap:8px !important;
    }
    .badges{
        display: flex;
        gap:8px;
    }
}

.instructionsScrollWrapper{
    height: 200px;
    flex-grow: 1;
    overflow-y: scroll;
    gap:10px;
    flex-direction: column;
    display:flex;
}

.instructionsContainer{
    display: flex;
    flex-direction: column;
    gap:12px;
}

.btn{
    all:unset;
    position: absolute;
    top:8px;
    right:8px;
    padding:0;
    width: 16px;
    height: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;


    svg{
        width: 12px;
        height: 12px;
    }

    &:hover{
        background: none;
        color:var(--ds_red_700);
    }

}

.generalInstructionsContainer{
    gap:10px;
    display: flex;
    flex-direction: column;
}

.generalInstruction{
    display: flex;
    flex-direction: column;
    gap:6px;
    padding-left: 24px;

    .generalInstructionLabel{
        display: flex;
        align-items: center;
        gap:8px;

        svg{
            width: 12px;
            height: 12px;

        }
    }
}

.select{
    border: 1px solid #333 !important;
    max-width: 80% !important;    
    background: var(--ds-gray-100, #1A1A1A) !important;

}

.emptyStateContainer{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    margin-top:100px;
    color:#D0D0D0;

    small{
        font-size: 13px;
        color: #878787 !important;
    }

    kbd{
        background-color: #1F1F1F !important;
        border:none !important;
        margin-right: 4px !important;
    }


}