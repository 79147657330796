.container {
  display: flex;
  border-radius: 8px;
  background: var(--ds_gray_200);
  padding: 16px;
  justify-content: space-between;
  .left {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 6px;
    > div {
      color: var(--ds_gray_1000);
      text-overflow: ellipsis;
      /* Text/13/Normal/400 */
      font-family: Geist;
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
      display: flex;
      gap: 8px;
      align-items: center;
      a {
        color: unset;
      }
      svg {
        width: 12px;
        height: 12px;
      }
    }

    .time {
      overflow: hidden;
      color: var(--ds-gray-600, #878787);
      text-overflow: ellipsis;
      /* Text/12/Normal/300 */
      font-family: Geist;
      font-size: 12px;
      font-style: normal;
      font-weight: 300;
      line-height: 12px;
    }
  }

  .right {
    align-items: center;
    display: flex;
    gap: 16px;

    label {
      color: var(--ds_gray_600);
      text-align: center;
      /* Text/12/Monospace/400 */
      font-family: "GeistMono";
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
    }
  }
}
