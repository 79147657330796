@import '../../../../theme.scss';

.container{
  padding: 10px;
  border-radius: 4px;
  background: var(--backgroundPaper);
  border: 1px solid var(--otherDivider);
  margin-top: 10px;
  display: flex;

  align-items: center;
  column-gap: 10px;
  color: var(--textPrimary);
  font-size: 18px;
  justify-content: space-between;
  div{
    display: flex;
    gap:8px;
    align-items: center;
  }

  button{
    all:unset;
    cursor: pointer ; 
    padding:4px;
    border-radius: 4px;

    &:hover{
      all:unset;
      cursor: pointer ; 
      padding:4px;
      border-radius: 4px;
      background:var(--otherDivider);
    }
  }
}
