@import "../../theme.scss";

.container {
  border: none;
  padding: 8px;
  padding-right: 16px;
  border-radius: 8px;
  display: flex;
  gap: 10px;
  color: unset;
  cursor: pointer;
  align-items: center;

  background-color: var(--otherBackdrop);
  &:hover {
    color: var(--textPrimary);
  }

  &:disabled {
    cursor: default !important;
    background: var(--actionDisabled);
    &:hover {
      background: var(--actionDisabled);
    }
  }
}

.S {
  svg {
    height: 16px;
    width: 16px;
  }
}
.M {
  svg {
    height: 24px;
    width: 24px;
  }
}

.primary {
  background: var(--primaryMain);
  color: var(--backgroundDefault);
  &:hover {
    background: var(--otherUser);
    color: var(--backgroundDefault);
  }
}

.comingSoon {
  cursor: default !important;
  &:hover {
    color: unset !important;
    border: 1px solid var(--actionDisabled) !important;
  }
}

.success {
  border: 1px solid var(--successGreen);
  border-radius: 8px;
  color: var(--successGreen);
  cursor: default;
  &:hover {
    border: 1px solid var(--successGreen);
    color: var(--successGreen);
  }
}

.previousStyle {
  border-radius: 100px !important;
}

.outline_primary {
  padding: 4px 8px;
  padding-right: 10px;
  border-radius: 100px;
  border: 1px solid var(--infoMain);
  color: var(--infoMain);
  background: unset;
  gap: 10px;
  &:hover {
    color: var(--infoLight);
    border: 1px solid var(--infoLight);
  }
}

.outline_secondary {
  padding: 4px;
  padding-right: 10px;
  border-radius: 100px;
  border: 1px solid var(--actionDisabled);
  background: unset;
  gap: 2px;
  &:hover {
    color: var(--textPrimary);
    border: 1px solid var(--textPrimary);
  }
}
