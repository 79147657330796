@import "../../../theme.scss";

.geistBadge {
  display: flex;
  padding: 6px 10px;
  justify-content: center;
  align-items: center;
  gap: 4px;

  width: fit-content;
  font-size: 12px;
  font-weight: 500;
  line-height: 12px;
  height: fit-content;
  font-family: "Geist";
  transition: all 0.3s;
  border-radius: 4px;
  background: var(--primaryContrast);
  color: var(--ds_gray_1000);

  svg {
    width: 14px;
    height: 14px;
  }

  &.red {
    background-color: var(--ds_red_200);
    color: var(--ds_red_900);
    border-radius: 32px;
  }

  &.green {
    background-color: var(--ds_green_200);
    color: var(--ds_green_900);
    border-radius: 32px;
  }

  &.purple {
    background: var(--ds-purple-200, #2e1938);
    color: var(--ds-purple-900, #bf7af0);
    border-radius: 32px;
  }

  &.yellow {
    background-color: var(--ds_yellow_900);
    color: var(--ds_yellow_600);
    border-radius: 32px;
  }

  &.blue {
    background-color: var(--blue_200);
    color: var(--blue_900);
    border-radius: 32px;
  }

  &.pretty {
    background: linear-gradient(
      105deg,
      var(--gradient_1) 0%,
      var(--gradient_2) 100%
    );
    color: var(--otherSnackbar);
    border-radius: 32px;
  }

  &.pointer {
    cursor: pointer;
  }

  &.smaller {
    font-size: 10px;
    line-height: 10px;
  }

  &.bigger {
    font-size: 16px;
    line-height: 16px;
  }

  &.load {
    @include skeleton;
    color: transparent;

    &::after {
      width: 600%;
    }
  }
}

@keyframes shine {
  from {
    transform: translateX(-100%);
    visibility: visible;
  }
  to {
    visibility: visible;
    transform: translateX(100%);
  }
}
