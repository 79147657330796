@import "../../theme.scss";

.mainContainer {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 6vh 10vw;
  background: var(--geist_background);
  height: 100%;
  font-family: Geist;

  .mainHeader {
    gap: 4px;
    display: flex;
    flex-direction: column;

    .selectTimeFilter {
      width: 18%;
      align-self: flex-end;
      font-size: 16px;

      &.loadSelect {
        color: transparent;
        font-size: 2px;
      }
    }

    .loadHead {
      @include skeleton;

      &::after {
        width: 576%;
        background: linear-gradient(
          110deg,
          rgba(255, 255, 255, 0) 20%,
          rgba(255, 255, 255, 0.2) 50%,
          rgba(255, 255, 255, 0) 80%
        );
        animation-delay: 0.05s;
      }
    }

    .loadSubhead {
      @include skeleton;

      &::after {
        width: 346%;
        background: linear-gradient(
          110deg,
          rgba(255, 255, 255, 0) 20%,
          rgba(255, 255, 255, 0.2) 50%,
          rgba(255, 255, 255, 0) 80%
        );
        animation-delay: 0.05s;
      }
    }

    .loadSelect {
      @include skeleton;

      &::after {
        width: 382%;
        background: linear-gradient(
          110deg,
          rgba(255, 255, 255, 0) 20%,
          rgba(255, 255, 255, 0.2) 50%,
          rgba(255, 255, 255, 0) 80%
        );
        animation-delay: 0.4s;
      }
    }

    @keyframes shine {
      from {
        transform: translateX(-100%);
        visibility: visible;
      }
      to {
        transform: translateX(100%);
        visibility: visible;
      }
    }
  }
}
