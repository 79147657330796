@import "../../theme.scss";

.textSecondary {
  color: var(--textSecondary);
}

.collapsable {
  padding: 8px;
  display: flex;
  flex-direction: column;
  h4 {
    color: var(--textPrimary);
    margin: 0;
  }
  > div {
    overflow: scroll;
  }
}
