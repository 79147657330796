@import '../../../../../theme.scss';

.container{
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width:100%;

  main{
    display: flex;
    gap: 20px;
  }

  footer{
    display: flex;
    justify-content: flex-end;
  }
}


.integrationButton{
  background: transparent;  
  color:var(--textPrimary);
  display: flex;
  padding:30px 50px;;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap:12px;
  border:1px solid var(--otherDivider);

  img{
    width: 80px;
  }
  &:hover{
    background: transparent;
    border:1px solid var(--textPrimary);
  }
}


.active{
  border:1px solid var(--successGreen)
}