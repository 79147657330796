.title {
    display: flex;
    align-items: center;

    svg{
      width:18px;
      height:18px;
      margin-right: 4px;
    }
  }
  
  .clickable {
    cursor: pointer;
    padding: 4px;
    width: fit-content;
    border-radius: 6px;
    &:hover {
      background-color: rgb(49, 49, 49);
    }
  }

  .bg{
    background: var(--primaryContrast);
    border-radius: 6px;
    padding:10px;

    .children{
      padding:10px 0;
    }

  }
  

  .fullWidth{
    width: 100%;
    gap:10px;
    padding-left: 10px;
    background: var(--primaryContrast);
    svg{
      width: 14px;
      height: 14px;
    }

    &:hover {
      background-color: var(--primaryContrast);
    }
  }

  .backgroundColor{
    background:#292929;
  }